import { useEffect, useState } from "react";
import moment from "moment";
import { FormValues, InputType, DateFormat } from "../FormGenerator/interface";
import { FormConfig } from "../../../services/BillingFulfillment/interface";
import {
  BillingFulfillmentFormConfigQuery,
  getBillingFulfillmentFormConfig,
} from "../../../services/BillingFulfillment/getBillingFulfillmentFormConfig";

export const useFormConfig = (query: BillingFulfillmentFormConfigQuery) => {
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [offerId, setOfferId] = useState<string>();
  const [formConfig, setFormConfig] = useState<FormConfig>();
  const [initialValues, setInitialValues] = useState<FormValues>();

  const formatInitialValues = (
    inputsConfig: FormConfig["inputs"],
    rawValues: FormValues
  ): FormValues => {
    const rawCopy = { ...rawValues };
    inputsConfig.forEach(({ type, name }) => {
      if (type !== InputType.date) return;
      const rawValue = rawCopy[name];
      if (typeof rawValue !== "string") {
        return;
      }
      const date = moment(rawValue, DateFormat);
      rawCopy[name] = date;
    });
    return rawCopy;
  };

  useEffect(() => {
    const handleFetch = async () => {
      setLoadingConfig(true);
      const { data } = await getBillingFulfillmentFormConfig(query);
      if (data) {
        setOfferId(data.offerId);
        setFormConfig(data.formConfig);
        const formatedValues = formatInitialValues(
          data.formConfig.inputs,
          data.formValues
        );
        console.log({ formatedValues, formValues: data.formValues });
        setInitialValues(formatedValues);
      }
      setLoadingConfig(false);
    };
    handleFetch();
  }, [query.offerId, query.tripId]);

  return {
    loadingConfig,
    offerId,
    formConfig,
    initialValues,
  };
};
