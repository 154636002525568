import React, { FunctionComponent } from "react";
import {
  Form,
  Row,
  Col,
  SpinnerInContainer,
  Button,
  Typography,
} from "@triporate/triporate-design-system";
import { FormGeneratorProps } from "./interface";
import FormInputs from "./FormInputs";
import "./style.scss";

const { Title, Text } = Typography;

const FormGenerator: FunctionComponent<FormGeneratorProps> = ({
  formData,
  loading,
  initialValues,
  onFinish,
}) => {
  if (loading) return <SpinnerInContainer />;
  return (
    <Form
      className="billing-fulfillment-form-generator"
      onFinish={onFinish}
      initialValues={initialValues}
      autoComplete="off"
      layout="vertical"
      scrollToFirstError
    >
      <Row justify="start" gutter={[8, 24]}>
        <Col span={24} className="billing-fulfillment-title-col">
          <Title level={2}>{formData.title}</Title>
          <Text>{formData.note}</Text>
        </Col>
        <Col span={24}>
          <FormInputs inputsData={formData.inputs} />
        </Col>
        <Col span={24} className="billing-fulfillment-save-btn-col">
          <Button type="primary" htmlType="submit">
            {formData.saveButton}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormGenerator;
