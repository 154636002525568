import { FetchService } from "../FetchService";
import { dashboardURI } from "./uri";

export interface HotelPreferencesInterface {
  hotel:        string;
  company:      string;
  traveller:    null;
  _id:          string;
  type:         string;
  reason:       string;
  hotelDetails: HotelDetails;
  __v?:         string
}

interface HotelDetails {
  city: string;
  name: string;
}



interface Response {
    error?: boolean;
    data?: HotelPreferencesInterface []
  }

export async function getCompanyHotelPreferences(slug: string): Promise<Response> {
    const fetchService = new FetchService();
  
    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/preferences";
  
    const { data, error } = await fetchService.get(url);
  
    if (!error) {
      const castedData = data as Response["data"];
      return { data: castedData };
    }
  
    return { error };
  }