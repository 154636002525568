import {
  FormConfig,
  InputData,
} from "../../../services/BillingFulfillment/interface";

export const DateFormat = "DD/MM/yyyy";

export type FormValues = Record<string, unknown>;

export interface FormGeneratorProps {
  formData: FormConfig;
  loading: boolean;
  initialValues?: FormValues;
  onFinish: (values: FormValues) => void;
}

export interface FormInputsProps {
  inputsData: InputData[];
}

export interface InputDataProps {
  data: InputData;
}

export enum InputType {
  "text" = "text",
  "num" = "num",
  "text/num" = "text/num",
  "select" = "select",
  "date" = "date",
}
