import React, { Fragment, FunctionComponent } from "react";
import { TripoCard, Row, Col } from "@triporate/triporate-design-system";
import { FormInputsProps } from "./interface";
import InputSwitch from "./InputSwitch";

const FormInputs: FunctionComponent<FormInputsProps> = ({
  inputsData,
  ...props
}) => {
  return (
    <TripoCard className="billing-fulfillment-form-inputs">
      <Row>
        {inputsData.map((data, key) => (
          <Col key={key} span={24}>
            <InputSwitch {...props} data={data} />
          </Col>
        ))}
      </Row>
    </TripoCard>
  );
};

export default FormInputs;
