import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import useFormMode from "../useFormMode";
import {
  getTravellerById,
  getTravellersFormConfig,
  getAgentsTravellerById,
} from "../../services/Travellers";
import {
  getFormItemIsVisible,
  inputValidation,
} from "../../services/InputValidation";
import {
  UseTravellerFormProps,
  SectionTitles,
  TravellersFormConfig,
  ConfirmationModalData,
} from "./types";
import useTravellerFormSubmit from "./useTravellerFormSubmit";

const useTravellerFormConfig = (
  props: UseTravellerFormProps
): TravellersFormConfig => {
  const { travellerFormSubmitHandler, loadingSubmit, modalData } =
    useTravellerFormSubmit(props);
  const [travellerId, mode] = useFormMode(props.paramName);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [formInputsConfig, setFormInputsConfig] = useState<
    TravellersFormConfig["formInputsConfig"]
  >([]);
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [sectionTitles, setSectionTitles] = useState<SectionTitles>({
    create: "",
    update: "",
  });
  const [btnText, setBtnText] = useState("");
  const [saveBtnText, setSaveBtnText] = useState("");
  const [confirmationModalData, setConfirmationModalData] =
    useState<ConfirmationModalData>();

  useEffect(() => {
    let isMounted = true;
    const handleTravellersFormConfigFetch = async () => {
      setLoadingConfig(true);
      const { data } = await getTravellersFormConfig();

      if (!isMounted) {
        return;
      }

      if (data) {
        setFormInputsConfig(data.inputs);
        setSectionTitles((prevState) => ({
          ...prevState,
          ...data.config.title,
        }));
        setBtnText((prevState) => data.config.headerButton || prevState);
        setSaveBtnText(data.config.saveButton);
        setConfirmationModalData(data.config.confirmationModal);
        if (data.config.formInitialValue && mode === "create") {
          setInitialValues(data.config.formInitialValue);
        }
      }
      setLoadingConfig(false);
    };
    handleTravellersFormConfigFetch();

    return () => {
      isMounted = false;
    };
  }, [mode]);

  const handleIsVisible = async (url: string, id: string) => {
    const formItemIsVisible = await getFormItemIsVisible(url, id);
    return formItemIsVisible;
  };

  const formatDates = (values: Record<string, unknown>) => {
    const dateInputs = [
      "dateOfBirth",
      "dniExpiryDate",
      "dniIssueDate",
      "passportExpiryDate",
      "passportIssueDate",
    ];

    for (const key in values) {
      if (dateInputs.includes(key)) {
        const newDateStr = values?.[key] as string;
        const date = moment(newDateStr, "DD-MM-YYYY");
        if (date.isValid()) values[key] = date;
      }
    }
    return values;
  };

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async (id: string) => {
      setLoadingInitialValues(true);
      const fetchMethod = props.agentsMode
        ? getAgentsTravellerById
        : getTravellerById;
      const { data } = await fetchMethod(id);

      if (!isMounted) return;

      if (data) {
        const validInitialValues = data as unknown as Record<string, unknown>;
        const formattedInitialValues = formatDates(validInitialValues);

        setInitialValues(formattedInitialValues);
      }
      setLoadingInitialValues(false);
    };

    if (travellerId) handleFetch(travellerId);
    else setLoadingInitialValues(false);

    return () => {
      isMounted = false;
    };
  }, [travellerId]);

  const inputValidationWithTravellerId = useCallback(
    (url: string, value: unknown) => {
      const valueWithId = { value, id: travellerId };
      return inputValidation(url, valueWithId);
    },
    [travellerId]
  );

  return {
    loadingConfig,
    loadingInitialValues,
    formInputsConfig,
    initialValues,
    sectionTitles,
    btnText,
    saveBtnText,
    confirmationModalData,
    travellerId,
    mode,
    inputValidationWithTravellerId,
    travellerFormSubmitHandler,
    loadingSubmit,
    handleIsVisible,
    modalData,
  };
};

export default useTravellerFormConfig;
