import { useState } from "react";
import { updateBillingFulfillment } from "../../../services/BillingFulfillment/updateBillingFulfillment";
import { FormSubmitProps } from "./types";

export type FormSubmitHandler = (
  values: Record<string, unknown>,
  id: string
) => Promise<void>;

export const useFormSubmit = ({
  onSuccess,
  onError,
}: FormSubmitProps): {
  formSubmitHandler: FormSubmitHandler;
  loadingSubmit: boolean;
} => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const formSubmitHandler: FormSubmitHandler = async (
    values: Record<string, unknown>,
    id: string
  ) => {
    setLoadingSubmit(true);
    const body = JSON.stringify(values);

    const { error, data } = await updateBillingFulfillment(body, id);

    if (!error) {
      onSuccess?.(data);
    } else {
      onError?.(data);
    }
    setLoadingSubmit(false);
  };

  return {
    formSubmitHandler,
    loadingSubmit,
  };
};
