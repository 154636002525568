import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  PaginatedList,
  List,
  Icons,
  validateIconType,
  Space,
} from "@triporate/triporate-design-system";
import { getOffices } from "../../../services/Offices";
import { ValidPath } from "../../../RouterSwitch";
import { OfficeConfigData, OfficeListData } from "./type";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";

const OfficeList = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState<OfficeListData>([]);
  const [officeConfigData, setOfficeConfigData] = useState<OfficeConfigData>();

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async () => {
      setLoading(true);
      const { data } = await getOffices();

      if (!isMounted) {
        return;
      }

      if (data) {
        if (data.list?.length > 0)
          setListData(data.list.sort((a, b) => (a.name > b.name ? 1 : -1)));
        setOfficeConfigData(data.config);
      }
      setLoading(false);
    };
    handleFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  const customRenderItem = ({
    name,
    id,
  }: Record<string, string | boolean | undefined>) => (
    <List.Item
      className="list-item"
      actions={[
        <Link
          to={`/${ValidPath.offices}/update/${id}`}
          key="list-loadmore-edit"
          className="no-text-underline space-beet"
        >


          <span>
            <Icons icon={validateIconType("edit")} />
            {officeConfigData?.list?.button}
          </span>



        </Link>,
        <Link
          to={`/${ValidPath.offices}/users/${id}`}
          key="list-users"
          className="no-text-underline space-beet"
        >
          <span>
            <UserOutlined />
            Users
          </span>


        </Link>,
      ]}
    >
      <List.Item.Meta
        title={
          <Link
            to={`/${ValidPath.offices}/update/${id}`}
            className="no-text-underline"
          >
            {name}
          </Link>
        }
      />
    </List.Item>
  );

  return (
    <PaginatedList
      listData={listData}
      configData={officeConfigData}
      loading={loading}
      customRenderItem={customRenderItem}
      handleNavigationClick={(to) => {
        history.push(`/${ValidPath.offices}${to}`);
      }}
    />
  );
};

export default OfficeList;
