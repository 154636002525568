import { FetchService } from "../FetchService";

async function handleDownload(response: Response) {

    if (!response.ok) {
        let msg = "Error al descargar el archivo: ";
        let ct = response.headers.get("Content-Type");
        if (ct && ct.indexOf("application/json") !== -1) {
            let { message } = await response.json() as any;
            msg += message;
        } else {
            msg += response.statusText;
        }
        throw new Error(msg);
    }

    let blob = await response.blob();

    let url = window.URL.createObjectURL(blob);

    let a = document.createElement("a");
    a.href = url;
    a.download = "travellers.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export async function downloadTravellers(companyId: string): Promise<void> {

    var fetchService = new FetchService();

    var url = new URL(`companies/${companyId}/travellers/download`, fetchService.baseUrl).toString();
    var options = {
        method: "GET",
        headers: {
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
    }

    let response = await fetchService.fetch(url, options);
    await handleDownload(response);
}