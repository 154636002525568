import React, { FunctionComponent, useEffect, useState } from "react";
import {
  PageHeader,
  FormGenerator,
  SpinnerInContainer,
} from "@triporate/triporate-design-system";
import { FormData } from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";
import { useHistory } from "react-router-dom";
import { useFormMode } from "../../../hooks";
import { ValidPath } from "../../../RouterSwitch";
import {
  getOfficeById,
  getOfficesFormConfig,
  upsertOffice,
} from "../../../services/Offices";
import { getSelectOptions } from "../../../services/SelectOptions";
import { panelNotification } from "../../../utils/panelNotification";
import GoBackHeaderButton from "../GoBackHeaderButton";
import { inputValidation } from "../../../services/InputValidation";
import { FormConfigData } from "../../../services/formConfigTypes";

const OfficesForm: FunctionComponent = () => {
  const history = useHistory();
  const [officeId, mode] = useFormMode("officeId");
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formInputsConfig, setFormInputsConfig] = useState<FormData[]>([]);
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [sectionTitles, setSectionTitles] = useState({
    create: "",
    update: "",
  });
  const [btnText, setBtnText] = useState("");
  const [saveBtnText, setSaveBtnText] = useState("");
  const [confirmationModalData, setConfirmationModalData] =
    useState<FormConfigData["config"]["confirmationModal"]>();

  useEffect(() => {
    let isMounted = true;
    const handleOfficesFormConfigFetch = async () => {
      setLoadingConfig(true);
      const { data } = await getOfficesFormConfig();

      if (!isMounted) {
        return;
      }

      if (data) {
        setFormInputsConfig(data.inputs);
        setSectionTitles((prevState) => ({
          ...prevState,
          ...data.config.title,
        }));
        setBtnText((prevState) => data.config.headerButton || prevState);
        setSaveBtnText(data.config.saveButton);
        setConfirmationModalData(data.config.confirmationModal);
      }
      setLoadingConfig(false);
    };
    handleOfficesFormConfigFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async (id: string) => {
      setLoadingInitialValues(true);
      const { data } = await getOfficeById(id);

      if (!isMounted) {
        return;
      }

      if (data) {
        const validInitialValues = data as unknown as Record<string, unknown>;
        setInitialValues(validInitialValues);
      }
      setLoadingInitialValues(false);
    };
    if (officeId) {
      handleFetch(officeId);
    } else {
      setLoadingInitialValues(false);
    }

    return () => {
      isMounted = false;
    };
  }, [officeId]);

  const handleFormSubmit = async (values: Record<string, unknown>) => {
    setLoadingSubmit(true);
    const body = JSON.stringify(values);

    const { error, data } = await upsertOffice(body, officeId);

    if (!error) {
      history.push(`/${ValidPath.offices}`);
      panelNotification("success", data);
    } else {
      setLoadingSubmit(false);
      panelNotification("error", data);
    }
  };

  if (loadingConfig || loadingInitialValues) {
    return <SpinnerInContainer />;
  }

  return (
    <>
      <PageHeader
        title={sectionTitles[mode]}
        extra={[
          <GoBackHeaderButton key="go-back-header-button" text={btnText} />,
        ]}
      />
      <FormGenerator
        submitButtonLabel={saveBtnText}
        formData={formInputsConfig}
        getSelectOptions={getSelectOptions}
        initialValues={initialValues}
        onFinish={handleFormSubmit}
        inputValidator={inputValidation}
        loading={loadingSubmit}
        isUpdate={!!officeId}
        confirmationModalData={confirmationModalData}
        mode={mode}
      />
    </>
  );
};

export default OfficesForm;
