import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Select,
  Tooltip,
  Row,
  Col,
  FormItem,
} from "@triporate/triporate-design-system";
import { getSelectOptions } from "../../../services/SelectOptions";
import {
  InputData,
  Option as SelectOptionType,
} from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";

interface RoleSelectProps {
  value?: string;
  onChange: (value?: string) => void;
  inputData: InputData;
}

const { Option } = Select;

export enum ValidRoles {
  agent = "agent",
  admin = "admin",
  superadmin = "superadmin",
  travel_manager = "travel_manager",
  traveller = "traveller",
}

const RoleSelect: FunctionComponent<RoleSelectProps> = ({
  onChange,
  value,
  inputData,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const handleOptionsFetch = useCallback(
    async (url: string) => {
      setLoading(true);
      const { data } = await getSelectOptions(url);
      if (data) {
        setOptions(data);
      }
      setLoading(false);
    },
    [setOptions]
  );

  useEffect(() => {
    if (inputData.optionsUri) {
      handleOptionsFetch(inputData.optionsUri);
    }
  }, [inputData.optionsUri, handleOptionsFetch]);

  return (
    <Row>
      <Col span={12}>
        <FormItem {...props} inputData={inputData}>
          <Select
            loading={loading}
            filterOption={true}
            optionFilterProp="label"
            value={value}
            onChange={(value) => onChange(`${value}`)}
            style={{ width: "100%" }}
          >
            {options.map(({ label, value, tooltip }, optionKey: number) => (
              <Option key={optionKey} value={value} label={label}>
                <Tooltip title={tooltip} placement="top">
                  {label}
                </Tooltip>
              </Option>
            ))}
          </Select>
        </FormItem>
      </Col>
    </Row>
  );
};

export default RoleSelect;
