import React, { FunctionComponent } from "react";
import { Layout } from "@triporate/triporate-design-system";
const { Content } = Layout;
import Sidebar from "./Sidebar";
import PanelHeader from "./Header";

import "./LayoutContainer.scss";

const LayoutContainer: FunctionComponent = ({ children }) => {
  return (
    <Layout className="layout-container">
      <Sidebar />
      <Layout className="layout">
        <PanelHeader />
        <Content className="content">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutContainer;
