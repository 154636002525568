import { useState } from "react";
import { ModalData } from "../../components/ModalWelcome/types";
import {
  upsertTraveller,
  upsertAgentsTraveller,
} from "../../services/Travellers";
import { UseTravellerFormProps, TravellerFormSubmitHandler } from "./types";

const useTravellerFormSubmit = ({
  agentsMode,
  onSuccess,
  onError,
}: UseTravellerFormProps): {
  travellerFormSubmitHandler: TravellerFormSubmitHandler;
  loadingSubmit: boolean;
  modalData?: ModalData;
} => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [modalData, setModalData] = useState<ModalData>();
  const travellerFormSubmitHandler: TravellerFormSubmitHandler = async (
    values: Record<string, unknown>,
    travellerId: string | null
  ) => {
    setLoadingSubmit(true);
    const body = JSON.stringify(values);
    const upsertMethod = agentsMode ? upsertAgentsTraveller : upsertTraveller;
    const { error, data } = await upsertMethod(body, travellerId);

    const response = { error, data };

    if (data?.modal) {
      return setModalData({ modal: data.modal, response });
    }

    if (!error) {
      onSuccess?.(data);
    } else {
      onError?.(data);
    }
    setLoadingSubmit(false);
  };

  return {
    travellerFormSubmitHandler,
    loadingSubmit,
    modalData,
  };
};

export default useTravellerFormSubmit;
