import { FetchService } from "../FetchService";
import { dashboardURI } from "./uri";

export interface PointOfInterest {
    _id:          string;
    name:         string;
    type:         string;
    address:      string;
    latitude:     number;
    longitude:    number;
    zone_cod:     string;
    cod:          number;
    zone_name:    string;
    state_name:   string;
    country_name: string;
}

interface Response {
    error?: boolean;
    data?: PointOfInterest[];
  }

export async function getCompanySedes(slug: string): Promise<Response> {
    const fetchService = new FetchService();
  
    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/pois";
  
    const { data, error } = await fetchService.get(url);
  
    if (!error) {
      const castedData = data as Response["data"];
      return { data: castedData };
    }
  
    return { error };
  }