import { FetchService } from "../FetchService";
import mockData from "./mockHeader.json";
import { store } from "../../store";
import {
  handleSetAdditionalConfig,
  AdditionalConfigState,
} from "../../store/additionalConfig";

export async function getAdditionalConfig(): Promise<void> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) {
    store.dispatch(handleSetAdditionalConfig(mockData));
    return;
  }

  const url = fetchService.baseUrl + "/admin-panel/init-config";
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as AdditionalConfigState["additionalConfig"];
    castedData.loadedAdditionalConfig = true;
    store.dispatch(handleSetAdditionalConfig(castedData));
  }
}
