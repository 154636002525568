import { FetchService } from "../FetchService";
import mockData from "./mockTravellerById.json";
import { travellerURI } from "./uri";

interface TravellerId {
  id: string;
}

interface Response {
  error?: boolean;
  data?: TravellerId;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getAgentsTravellerById(
  agentsTravellerId: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url =
    fetchService.baseUrl +
    travellerURI +
    `/create-from-agents/${agentsTravellerId}`;
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
