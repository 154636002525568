import { FetchService } from "../FetchService";
import { HotelPreferencesInterface } from "./getCompanyHotelPreferences";
import { dashboardURI } from "./uri";


interface Response {
    error?: boolean;
    data?: HotelPreferencesInterface[];
  }

export async function addCompanyHotelPreference(slug: string, hotelId:string, type:string
): Promise<Response> {

  const body = {
    hotelId,
    type,
  };
    const fetchService = new FetchService();
  
    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/preference";
  
    const { data, error } = await fetchService.post(url, JSON.stringify(body));
  
    if (!error) {
      const castedData = data as Response["data"];
      return { data: castedData };
    }
  
    return { error };
  }