import { FetchService } from "../FetchService";
import mockData from "./mockTravellers.json";
import { travellerURI } from "./uri";

interface TravellersData {
  config: TravellersConfig;
  list: Traveller[];

  meta: Meta
}

interface TravellersConfig {
  list: ConfigItem;
  pageHeader: ConfigItem;
  enableModal: EnableModal;
}

interface EnableModal {
  patterns: Record<string, string>;
  actions: Record<string, string>;
  message: string;
  okText: string;
  cancelText: string;
}

interface ConfigItem {
  title: string;
  button: string;
  searchPlaceholder?: string;

  text: string
}

interface Traveller {
  name: string;
  id: string;
  company: string;
  enable: boolean;
}

interface Response {
  error?: boolean;
  data?: TravellersData;
}

export interface IParams {
  page?: number,
  pageSize?: number,
  search?: string
}

const handleMockData = (): Response => {
  return { data: mockData as any, error: false };
};

export async function getTravellers(params?: IParams): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();


  const buildedParams = params ? buildQueryParams({
    page: params.page,
    limit: params.pageSize,
    search: params.search
  }) : ''

  const url = fetchService.baseUrl + travellerURI + "/list" + buildedParams;
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}

function buildQueryParams(params: IParams & { limit?: number }): string {
  const queryString = Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc.push(`${key}=${encodeURIComponent(value)}`);
    }
    return acc;
  }, [] as string[]).join('&');

  return queryString ? `?${queryString}` : '';
}

export interface Meta {
  page: number,
  pageSize: number

  totalElements: number
}