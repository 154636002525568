import { FunctionComponent, useEffect, useState } from "react";
import {
    AutoComplete,
    Button,
    Input,
    Modal,
    TripoCard,
} from "@triporate/triporate-design-system";
import "../style.scss";
import {
    Budget,
    getCompanyBudgets,
} from "../../../services/Companies/getCompanyBudgets";
import { deleteCompanyBudgetHotel } from "../../../services/Companies/deleteCompanyBudgetHotel";
import { getOptionsBudgetLocation } from "../../../services/Companies/getOptionsBudgetLocation";
import { addBudgetHotel } from "../../../services/Companies/addBudgetHotel";
import { editBudgetGlobal } from "../../../services/Companies/editBudgetGlobal";
import { VALID_ROLES_WRITE_TRAVEL_POLICIES } from "../../../utils/constants";

type BudgetForAccommodationsProps = {
    slug: string | null;
    role: string;
};

const BudgetForAccommodations: FunctionComponent<
    BudgetForAccommodationsProps
> = ({ slug = null, role }) => {
    //------------------STATES---------------------//

    const [budgetList, setBudgetList] = useState<Budget[]>([]);
    const [optionsCityCountry, setOptionCityCountry] = useState<
        { value: string; label: string; type: string }[]
    >([]);
    const [cityCountry, setCityCountry] = useState<string>("");
    const [regionType, setRegionType] = useState<string>("");
    const [budget, setBudget] = useState<string>("");
    const [globalBudget, setGlobalBudget] = useState<number>(0);
    const [isShowModalConfirmation, setIsShowModalConfirmation] =
        useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>("");

    //---------------------EFFECTS---------------------------//

    useEffect(() => {
        if (slug) {
            getBudgetList(slug);
        }
    }, [slug]);

    //---------------------GET DATA---------------------------//

    const getBudgetList = async (slug: string) => {
        const { data } = await getCompanyBudgets(slug);
        if (data) {
            setBudgetList(data);
            setGlobalBudget(data[0]?.global ? data[0].global : 0);
        }
    };

    //---------------------DELETE DATA---------------------------//
    const deleteBudget = async () => {
        if (slug && idToDelete !== "") {
            const { data } = await deleteCompanyBudgetHotel(slug, idToDelete);
            if (data) {
                setBudgetList([data]);
                setIdToDelete("");
                setIsShowModalConfirmation(false);
            }
        }
    };

    //------------------HANDLERS---------------------//

    const handleDeleteBudgetHotel = (id: string) => {
        setIdToDelete(id);
        setIsShowModalConfirmation(true);
    };

    const handleSearchCityCountry = async (text: string) => {
        const { data } = await getOptionsBudgetLocation(text);
        if (data) {
            const codes: any = [];
            data.forEach((code: any) => {
                let object = {};

                if (code.loctype === "ctry") {
                    object = {
                        label: `🏴 ${code.displayname}`,
                        value: code.displayname,
                        type: code.loctype,
                    };
                    codes.push(object);
                }
                if (code.loctype === "city") {
                    object = {
                        label: `🏙️ ${code.displayname}`,
                        value: code.displayname,
                        type: code.loctype,
                    };
                    codes.push(object);
                }
            });
            setOptionCityCountry(codes);
        }
    };

    const handleOnSelectCityCountry = (data: string) => {
        const value = data;
        if (value !== null && value !== "") {
            const cityCountry = optionsCityCountry.find(
                (option: any) => option.value === data
            );
            setCityCountry(cityCountry ? cityCountry.label : "");
            setRegionType(cityCountry ? cityCountry.type : "");
        }
    };

    const handleChangeCityCountryLabel = async (event: string) => {
        setCityCountry(event);
    };

    const handleClickAddBudgetHotel = async () => {
        if (slug) {
            let region = {};
            let value = cityCountry
                .replace(/[\u{1F300}-\u{1F6FF}]/gu, "")
                .split(",")[0];
            if (regionType !== null && regionType !== "") {
                region =
                    regionType === "ctry"
                        ? {
                              country: value,
                              breakfast: false,
                              cancelable: false,
                          }
                        : {
                              city: value,
                              breakfast: false,
                              cancelable: false,
                          };

                const { data } = await addBudgetHotel(
                    slug,
                    region,
                    Number(budget)
                );
                if (data) {
                    setOptionCityCountry([]);
                    setBudget("");
                    setCityCountry("");
                    setRegionType("");
                    setBudgetList([data]);
                }
            }
        }
    };

    const onBlurBudgetGlobalHanlder = async (
        event: any,
        globalBudget: number
    ) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (slug) {
                const { data } = await editBudgetGlobal(slug, globalBudget);
                if (data) {
                    setBudgetList([data]);
                    removeFocus("editInput-global");
                }
            }
        }
    };

    const onFocusEditableGlobal = (event: any, key: string) => {
        event.preventDefault;
        const element = document.getElementById(key);
        if (element) {
            element.focus();
        }
    };

    const removeFocus = (key: string) => {
        const element = document.getElementById(key);
        if (element) {
            element.blur();
        }
    };

    return (
        <>
            <TripoCard>
                <div className="card">
                    <h1 className="title">Presupuestos para alojamiento</h1>
                    {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                        <>
                            <div>
                                <h2 className="subtitle">Nuevo presupuesto</h2>
                            </div>
                            <div className="section-inputs-points-of-interest">
                                <div className="input">
                                    <span>Ciudad o País</span>
                                    <AutoComplete
                                        value={cityCountry}
                                        onSearch={(text) =>
                                            handleSearchCityCountry(text)
                                        }
                                        onChange={(event) =>
                                            handleChangeCityCountryLabel(event)
                                        }
                                        onSelect={handleOnSelectCityCountry}
                                        style={{ width: 170 }}
                                        options={optionsCityCountry}
                                    />
                                </div>
                                <div className="input">
                                    <span>Precio</span>
                                    <Input
                                        value={budget}
                                        onChange={(event) =>
                                            setBudget(event.target.value)
                                        }
                                    />
                                </div>

                                <div>
                                    <span> € </span>
                                    <span>Noche/Persona</span>
                                </div>

                                <Button
                                    type="primary"
                                    onClick={handleClickAddBudgetHotel}
                                    disabled={
                                        Number(budget) < 1 ||
                                        regionType.length < 1
                                    }
                                >
                                    Agregar
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <div className="card-grouped card-margin">
                    <div className="card-list">
                        <span>
                            <i className="icon-map-marker-alt-solid" />
                            Presupuesto global
                        </span>
                        <div>
                            <div className="input-with-addon">
                                {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) ? (
                                    <input
                                        type="text"
                                        className="inputNumero"
                                        value={globalBudget}
                                        id="editInput-global"
                                        contentEditable="true"
                                        onChange={(e) =>
                                            setGlobalBudget(
                                                Number(e.target.value)
                                            )
                                        }
                                        onKeyDown={(e) =>
                                            VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) &&
                                            onBlurBudgetGlobalHanlder(
                                                e,
                                                globalBudget
                                            )
                                        }
                                        suppressContentEditableWarning
                                    />
                                ) : (
                                    <span className="margin-global-budget">
                                        {globalBudget}
                                    </span>
                                )}

                                <span className="addon">
                                    € por persona/noche
                                </span>
                            </div>
                        </div>

                        {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                            <div>
                                <i
                                    className="icon-pen-solid cursor-pointer"
                                    onClick={(e) =>
                                        onFocusEditableGlobal(
                                            e,
                                            "editInput-global"
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>

                    {budgetList?.map((budget) =>
                        budget?.hotel?.map((hotel) => (
                            <div key={hotel._id} className="card-list">
                                <span>
                                    <i className="icon-map-marker-alt-solid" />
                                    {hotel?.region?.city ||
                                        hotel?.region?.country}
                                </span>
                                <span>
                                    {hotel.budget} €{" "}
                                    <span>por noche/persona</span>
                                </span>

                                {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                                    <div>
                                        <i
                                            className="icon-trash-alt-regular cursor-pointer"
                                            onClick={() =>
                                                handleDeleteBudgetHotel(
                                                    hotel._id
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </TripoCard>

            <Modal
                visible={isShowModalConfirmation}
                onOk={deleteBudget}
                okText={"Aceptar"}
                onCancel={() => {
                    setIsShowModalConfirmation(false);
                }}
                cancelText={"Cancelar"}
            >
                <div>
                    <h2>Presupuestos para alojamiento</h2>
                    <span>¿Esta seguro de eliminar este presupuesto para alojamiento?</span>
                </div>
            </Modal>
        </>
    );
};

export default BudgetForAccommodations;
