import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import TravellersSwitch from "../../components/Travellers/TravellersSwitch";

const Travellers = (): ReactElement => {
  return (
    <Layout>
      <TravellersSwitch />
    </Layout>
  );
};

export default Travellers;
