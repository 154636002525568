import { FetchService } from "../FetchService";
import { FormConfigData } from "../formConfigTypes";
import { universeURI } from "./uri";
import mockData from "./mockUniverseFormConfig.json";

interface Response {
  error?: boolean;
  data?: FormConfigData;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getUniverseFormConfig(): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + universeURI + "/form-config";

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
