import { FetchService } from "../FetchService";

interface Response {
    error?: boolean;
    data?: any;
}

export async function getOptionsBudgetLocation(
    inputValue: string
): Promise<Response> {
    const array: any = [];
    if (inputValue !== "" && inputValue.length > 2) {
        const body: any = { text: inputValue };
        const fetchService = new FetchService();

        const url = `${fetchService.baseUrl}/preferences/autocomplete?cityCountry=true`;

        const { data, error } = await fetchService.post(url, JSON.stringify(body));

        if (!error) {
            const castedData = data as Response["data"];
            return { data: castedData };
        }

        return { error };
    } else {
        return array;
    }
}
