import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";

import { ConfigState } from "../store/config";

function useGoogleTagManager(): void {
  const mapState = (state: ConfigState): string =>
    state.config.googleTagManagerId;
  const googleTagManagerId = useSelector(mapState);

  const tagManagerArgs = {
    gtmId: googleTagManagerId,
    dataLayerName: "admin-panel",
  };

  if (googleTagManagerId) TagManager.initialize(tagManagerArgs);
}

export default useGoogleTagManager;
