import { notification } from "@triporate/triporate-design-system";

export const panelNotification = (
  type: string,
  data: {
    message: string;
    description: string;
  }
): void => {
  const content = {
    duration: type === "error" ? 0 : 4,
    message: data.message,
    description: data.description,
  };
  switch (type) {
    case "success":
      notification.success(content);
      break;

    case "error":
      notification.error(content);
      break;
    default:
      break;
  }
};
