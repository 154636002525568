import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Select } from "@triporate/triporate-design-system";
import { getSelectOptions, Option } from "../../../../services/SelectOptions";
import { InputDataProps } from "../interface";
import FormItem from "./FormItem";

const SelectInput: FunctionComponent<InputDataProps> = ({ data, ...props }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(true);

  const handleOptionsFetch = useCallback(
    async (url: string) => {
      if (!getSelectOptions) return;
      setLoading(true);
      const { data: optionsData } = await getSelectOptions(url);

      if (optionsData) {
        Array.isArray(optionsData)
          ? setOptions(optionsData.sort((a, b) => (a.label > b.label ? 1 : -1)))
          : setOptions([optionsData]);
      }

      setLoading(false);
    },
    [getSelectOptions, setOptions]
  );

  useEffect(() => {
    if (data.optionsUri) {
      handleOptionsFetch(data.optionsUri);
    }
  }, [data, handleOptionsFetch]);

  const filterOption = (input: string, option?: Option) => {
    const lowerCaseLabel = (option?.label ?? "").toLowerCase();
    const lowerCaseInput = input.toLowerCase();
    return lowerCaseLabel.includes(lowerCaseInput);
  };
  return (
    <FormItem {...props} data={data}>
      <Select
        loading={loading}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        options={options}
      />
    </FormItem>
  );
};

export default SelectInput;
