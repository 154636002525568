import React, { FunctionComponent } from "react";
import { Switch, Route } from "react-router-dom";
import { ValidPath } from "../../../RouterSwitch";
import TravellersForm from "../EmbeddedTravellersForm";
import BillingFulfillmentForm from "../EmbeddedBillingFulfillmentForm";

const EmbeddedSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={`/${ValidPath.embedded}/${ValidPath.billingFulfillment}/update/:offerId`}
        component={BillingFulfillmentForm}
      />
      <Route
        path={`/${ValidPath.embedded}/${ValidPath.travellers}/create`}
        component={TravellersForm}
      />
      <Route
        path={`/${ValidPath.embedded}/${ValidPath.travellers}/update/:travellerId`}
        component={TravellersForm}
      />
    </Switch>
  );
};

export default EmbeddedSwitch;
