import { FetchService } from "../FetchService";


interface Response {
    error?: boolean;
    data?: any;
}

export async function getPoiData(placeId: string): Promise<Response> {
 
        const fetchService = new FetchService();

        const url = `${fetchService.baseUrl}/autocomplete/autocomplete-google/${placeId}`;

        const { data, error } = await fetchService.get(url);

        if (!error) {
            const castedData = data as Response["data"];
            return { data: castedData };
        }

        return { error };
    } 

