import { FetchService } from "../FetchService";

interface UpsertNotification {
  message: string;
  description: string;
}

interface Response {
  error?: boolean;
  data: UpsertNotification;
}

export async function upsertOffice(
  body: string,
  id: string | null
): Promise<Response> {
  const fetchService = new FetchService();
  let url = fetchService.baseUrl + "/office/form";

  if (id) {
    url += `/${id}`;
    const { error, data } = await fetchService.put(url, body);
    return { error, data: data as UpsertNotification };
  } else {
    const { error, data } = await fetchService.post(url, body);
    return { error, data: data as UpsertNotification };
  }
}
