import { FetchService } from "../FetchService";
import { Budget } from "./getCompanyBudgets";
import { dashboardURI } from "./uri";


interface Response {
    error?: boolean;
    data?: Budget;
  }

export async function editBudgetGlobal(slug: string, budget:number
): Promise<Response> {

  const body = {
    budget,
  };
    const fetchService = new FetchService();
  
    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/budget/global";
  
    const { data, error } = await fetchService.post(url, JSON.stringify(body));
  
    if (!error) {
      const castedData = data as Response["data"];
      return { data: castedData };
    }
  
    return { error };
  }