import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import MultiverseSwitch from "../../components/Multiverse/MultiverseSwitch";

const Multiverse = (): ReactElement => {
  return (
    <Layout>
      <MultiverseSwitch />
    </Layout>
  );
};

export default Multiverse;
