import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useAvailablePath } from "../hooks";
import {
  Offices,
  Travellers,
  Companies,
  Users,
  Multiverse,
  Universe,
  Embedded,
} from "../pages";

export enum ValidPath {
  embedded = "embedded",
  users = "users",
  offices = "offices",
  companies = "companies",
  travellers = "travellers",
  multiverse = "multiverse",
  config = "config", // universe
  billingFulfillment = "billingFulfillment",
}

const RouterSwitch: FunctionComponent = () => {
  const [availablePathDictionary, defaultPath] = useAvailablePath();

  return (
    <Switch>
      <Route path={`/${ValidPath.embedded}`} component={Embedded} />

      {availablePathDictionary.offices && (
        <Route path={`/${ValidPath.offices}`} component={Offices} />
      )}

      {availablePathDictionary.companies && (
        <Route path={`/${ValidPath.companies}`} component={Companies} />
      )}

      {availablePathDictionary.users && (
        <Route path={`/${ValidPath.users}`} component={Users} />
      )}

      {availablePathDictionary.travellers && (
        <Route path={`/${ValidPath.travellers}`} component={Travellers} />
      )}

      {availablePathDictionary.config && (
        <Route path={`/${ValidPath.config}`} component={Universe} />
      )}

      {availablePathDictionary.multiverse && (
        <Route path={`/${ValidPath.multiverse}`} component={Multiverse} />
      )}

      {defaultPath && <Redirect from="*" to={`/${defaultPath}`} />}
    </Switch>
  );
};

export default RouterSwitch;
