import { useParamById } from "../";

export enum FormMode {
  create = "create",
  update = "update",
}

function useFormMode(paramId: string): [string | null, keyof typeof FormMode] {
  const [id] = useParamById(paramId);
  const mode = id ? FormMode.update : FormMode.create;
  return [id, mode];
}

export default useFormMode;
