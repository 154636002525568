import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "@triporate/triporate-design-system";
import { AuthorizationService } from "../../../../services/Authorization";
import { handleAuthentication } from "../../../../store/auth";

const Login = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    const AuthService = new AuthorizationService();
    AuthService.authValidation().then((isAuthenticated) => {
      if (!isAuthenticated) AuthService.redirectToLogin();
      dispatch(handleAuthentication(isAuthenticated));
    });
  }, [dispatch]);

  return <Spinner />;
};

export default Login;
