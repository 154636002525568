import { Config, initialConfig } from "../../config/confgInterface";
export type State = Config;

export const initialState: State = initialConfig;

export const SETCONFIG = "SETCONFIG";

interface SetConfig {
  type: typeof SETCONFIG;
  payload: State;
}

export type ActionTypes = SetConfig;
