import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import MultiverseForm from "../MultiverseForm";
import { ValidPath } from "../../../RouterSwitch";

const MultiverseSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={`/${ValidPath.multiverse}`} component={MultiverseForm} />
      <Redirect from="*" to={`/${ValidPath.multiverse}`} />
    </Switch>
  );
};

export default MultiverseSwitch;
