import { PromiseType } from "utility-types";
import { InputValidator } from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";
import { FetchService } from "../FetchService";

type ValidationResponse = PromiseType<ReturnType<InputValidator>>;

const handleMockData = () => ({
  validValue: false,
  errorMessage: "Invalid",
});

export async function inputValidation(
  uri: string,
  value: unknown
): Promise<ValidationResponse> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  try {
    const url = fetchService.baseUrl + uri;
    const strBody =
      typeof value !== "string"
        ? JSON.stringify(value)
        : JSON.stringify({ value });

    const { data } = await fetchService.post(url, strBody);

    return data as ValidationResponse;
  } catch (e) {
    return handleMockData();
  }
}
