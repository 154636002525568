import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Image,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AdditionalConfigState } from "../../../store/additionalConfig";
import { ValidPath } from "../../../RouterSwitch";
import { getDefaultSelecteKey } from "./utils";
import { useAvailablePath } from "../../../hooks";

import "./PanelSidebar.scss";
const { Sider } = Layout;

const PanelSidebar = (): JSX.Element => {
  const mapState = (
    state: AdditionalConfigState
  ): AdditionalConfigState["additionalConfig"] => state.additionalConfig;
  const { sidebar } = useSelector(mapState);
  const location = useLocation();
  const [availablePathDictionary] = useAvailablePath();

  const [menuItems, setMenuItems] = useState<
    {
      label: string;
      icon: string;
      pathName: ValidPath;
      path: string;
      roles: string[];
    }[]
  >([]);
  const [pathList] = useState(Object.values(ValidPath));

  useEffect(() => {
    setMenuItems(
      pathList
        .map((pathName) => {
          const currentItem = sidebar.items[pathName] || {};
          return {
            ...currentItem,
            pathName,
            path: `/${pathName}`,
          };
        })
        .filter((item) => item.label)
    );
  }, [pathList, sidebar]);

  return (
    <Sider style={{ minHeight: "100vh" }}>
      <div className="logo">
        <Image
          width={130}
          src={sidebar?.agency?.logo}
          alt={sidebar?.agency?.name}
          preview={false}
        />
      </div>
      <Menu
        defaultSelectedKeys={getDefaultSelecteKey({
          pathList,
          pathName: location.pathname,
        })}
        mode="inline"
      >
        {menuItems.map((item) => {
          if (availablePathDictionary[item.pathName])
            return (
              <Menu.Item key={item.path}>
                <Link className="no-text-underline" to={item.path}>
                  <Icons icon={validateIconType(item.icon)} />
                  {item.label || item.path}
                </Link>
              </Menu.Item>
            );
        })}
      </Menu>
    </Sider>
  );
};

export default PanelSidebar;
