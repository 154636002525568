import { FetchService } from "../FetchService";
import { companyURI } from "./uri";
import { ConfigData } from "@triporate/triporate-design-system/dist/components/data-display/PaginatedList/type";
import mockData from "./mockCompanies.json";

interface OfficeData {
  config: ConfigData;
  list: Office[];
}

interface Office {
  name: string;
  id: string;
}

interface Response {
  error?: boolean;
  data?: OfficeData;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getCompanies(): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + companyURI + "/list";

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
