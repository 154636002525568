import { ModalWelcomeData } from "../../components/ModalWelcome/types";
import { FetchService } from "../FetchService";
import { travellerURI } from "./uri";

interface FailedUpsert {
  message: string;
  description: string;
  modal?: ModalWelcomeData;
}

interface Response {
  error?: boolean;
  data: FailedUpsert;
}

export async function upsertAgentsTraveller(
  body: string,
  agentsTravellerId: string | null
): Promise<Response> {
  const fetchService = new FetchService();
  const url =
    fetchService.baseUrl +
    travellerURI +
    `/create-from-agents/${agentsTravellerId}`;
  const { error, data } = await fetchService.post(url, body);
  const castedData = data as FailedUpsert;
  return { error, data: castedData };
}
