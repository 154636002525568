import { FetchService } from "../FetchService";
import { multiverseURI } from "./uri";
import mockData from "./mockMultiverse.json";

interface Response {
  error?: boolean;
  data?: unknown;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getMultiverse(): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + multiverseURI + "/form-values";

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
