import { store } from "../../store";
import { AuthenticationTokenHandler } from "@triporate/triporate-design-system";

export default class AuthToken {
  protected loginUrl: string;
  public baseUrl: string;
  public useFakeApi?: string;
  private tokenHandler: AuthenticationTokenHandler;

  public constructor() {
    const { config } = store.getState();
    this.loginUrl = config.loginClientUrl;
    this.baseUrl = config.apiHost;
    this.useFakeApi = process.env.REACT_APP_USE_FAKE_API;
    this.tokenHandler = new AuthenticationTokenHandler();
  }
  protected getToken(): string | null {
    return this.tokenHandler.getToken();
  }
  protected removeToken(): void {
    return this.tokenHandler.removeToken();
  }
}
