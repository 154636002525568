interface SubMenuItem {
  label: string;
  icon: string;
  path: string;
}
export interface State {
  role?: string;
  loadedAdditionalConfig: boolean;
  tab: {
    favicon: string;
  };
  header: {
    user: {
      initials: string;
      fullName: string;
    };
  };
  sidebar: {
    agency: {
      logo: string;
      name: string;
    };
    items: {
      [path: string]: {
        label: string;
        icon: string;
        subMenu?: SubMenuItem[];
        roles: string[];
        path?: string;
      };
    };
  };
}

export const SET_ADDITIONAL_CONFIG = "SET_ADDITIONAL_CONFIG";
export const SET_EMBEDDED_MODE_CONFIG = "SET_EMBEDDED_MODE_CONFIG";

interface SetAdditionalConfig {
  type: typeof SET_ADDITIONAL_CONFIG;
  payload: State;
}

interface SetEmbeddedModeConfig {
  type: typeof SET_EMBEDDED_MODE_CONFIG;
}
export type ActionTypes = SetAdditionalConfig | SetEmbeddedModeConfig;
