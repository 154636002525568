import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AdditionalConfigState } from "../store/additionalConfig";
import { ValidPath } from "../RouterSwitch/RouterSwitch";

type ValidPathKey = keyof typeof ValidPath;

function useAvailablePath(): [Record<ValidPathKey, boolean>, string | null] {
  const mapState = (
    state: AdditionalConfigState
  ): AdditionalConfigState["additionalConfig"] => state.additionalConfig;
  const { sidebar, role: userRole } = useSelector(mapState);

  const [pathDictionary] = useState(ValidPath);
  const [pathList] = useState(Object.values(pathDictionary));
  const [defaultPath, setDefaultPath] = useState<string | null>(null);
  const [availablePathDictionary, setAvailablePathDictionary] = useState<
    Record<ValidPathKey, boolean>
  >({
    embedded: false,
    offices: false,
    users: false,
    companies: false,
    travellers: false,
    config: false,
    multiverse: false,
    billingFulfillment: false,
  });

  useEffect(() => {
    setAvailablePathDictionary((prevAvailablePathDictionary) =>
      pathList.reduce<Record<ValidPathKey, boolean>>((dictionary, path) => {
        const currentItem = sidebar.items[path] || {};
        const pathIsAvailable = currentItem?.roles?.includes(userRole || "");
        return { ...dictionary, [path]: pathIsAvailable };
      }, prevAvailablePathDictionary)
    );
  }, [setAvailablePathDictionary, pathList, sidebar, userRole, pathDictionary]);

  useEffect(() => {
    const availablePath = Object.entries(availablePathDictionary).filter(
      ([pathName, isAvailable]) => isAvailable
    );

    if (availablePath.length) {
      const firstAvailablePath = availablePath[0];
      const firstAvailablePathName = firstAvailablePath[0];
      setDefaultPath(firstAvailablePathName);
    }
  }, [availablePathDictionary]);

  return [availablePathDictionary, defaultPath];
}

export default useAvailablePath;
