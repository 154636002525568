import { FetchService } from "../FetchService";
import mockData from "./mockUsers.json";

interface UsersData {
    config: UsersConfig;
    list: User[];
}

interface UsersConfig {
    list: ConfigItem;
    pageHeader: ConfigItem;
}

interface ConfigItem {
    title: string;
    button: string;
    text?: string;
}

interface User {
    name: string;
    id: string;
    email: string;
    role: string;
    officeName?: string;
    officeId?: string;
}

interface Response {
    error?: boolean;
    data?: UsersData;
}

const handleMockData = (): Response => {
    return { data: mockData, error: false };
};

export async function getUsersByOffice(officeId: string): Promise<Response> {
    const fetchService = new FetchService();
    if (fetchService.useFakeApi) return handleMockData();

    const url = fetchService.baseUrl + `/users/list?officeId=${officeId}`;
    const { data, error } = await fetchService.get(url);

    if (!error) {
        const castedData = data as Response["data"];
        return { data: castedData };
    }

    return { error };
}
