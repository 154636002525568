import { FetchService } from "../FetchService";
import { dashboardURI } from "./uri";

export interface deletePoi {
    location: Location;
    status: string;
    createdFrom: string;
    alternativeNames: any[];
    priority: number;
    _id: string;
    name: string;
    type: string;
    companySlug: string;
    address: string;
    latitude: number;
    longitude: number;
    zone_cod: string;
    cod: number;
    zone_name: string;
    state_name: string;
    country_name: string;
    country_iso: string;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
}
interface Location {
    type: string;
    coordinates: number[];
}

interface Response {
    error?: boolean;
    data?: deletePoi[];
}

export async function deleteCompanySede(slug: string, id:string): Promise<Response> {
    const fetchService = new FetchService();

    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/poi/" + id;

    const { data, error } = await fetchService.delete(url);
    if (!error) {
        const castedData = data as Response["data"];
        return { data: castedData };
    }

    return { error };
}
