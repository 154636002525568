import { FetchService } from "../FetchService";

interface ImageSrc {
  url: string;
}

interface ReqBody {
  secure_url: string;
}

interface Response {
  error?: boolean;
  data?: ImageSrc;
}

export const uploadAsset = async (file: unknown): Promise<Response> => {
  const fetchService = new FetchService();
  const url = fetchService.baseUrl + "/image/upload";

  const { error, data } = await fetchService.postAsset(url, file);

  if (data) {
    const castedData = data as ReqBody;
    return {
      data: {
        url: castedData.secure_url,
      },
    };
  }

  return { error };
};
