import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    List,
    Icons,
    validateIconType,
    PaginatedList,
    Avatar,
    Typography,
    Modal,
} from "@triporate/triporate-design-system";
import { deleteUser } from "../../../services/Users";
import { ValidPath } from "../../../RouterSwitch";
import { UsersConfigData, UsersListData } from "../../Users/List/type";
import { getUsersByOffice } from "../../../services/Users/getUsersByOffice";
import useParamById from '../../../hooks/useParamById';
import { panelNotification } from "../../../utils/panelNotification";

const { Text } = Typography;

export enum ValidDisplayRoles {
    agent = "Agent",
    admin = "Admin",
    travel_manager = "Travel Manager",
    traveller = "Traveller",
    superadmin = "Super Admin",
}

const Users = (): JSX.Element => {
    const history = useHistory();
    const [id] = useParamById("officeId")
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
    const [listData, setListData] = useState<UsersListData>([]);
    const [usersConfigData, setUsersConfigData] = useState<UsersConfigData>();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = React.useState<string>('');

    useEffect(() => {
        let isMounted = true;

        const handleFetch = async () => {

            if (!id) return

            setLoading(true);

            const { data } = await getUsersByOffice(id);

            if (!isMounted) {
                return;
            }

            if (data) {
                setListData(data.list.sort((a, b) => (a.name > b.name ? 1 : -1)));
                setUsersConfigData(data.config);
            }
            setLoading(false);
        };
        handleFetch();

        return () => {
            isMounted = false;
        };
    }, []);


    const handleDeleteUser = async (id?: any) => {
        if (id) {
            setOpenModal(true)
            setSelectedUserId(id);
        } else { 
            setLoadingDelete(true)
            try {
                const response = await deleteUser(selectedUserId)
                setOpenModal(false)
                setLoadingDelete(false)

                if (response?.error) {
                    panelNotification('error', { message: 'Algo salió mal', description: '' })
                } else {
                    panelNotification('success', { message: 'Eliminado con éxito', description: '' })
                    setListData((prevState: any) => prevState.filter((item: any) => item.id != selectedUserId))
                }
            } catch (error) {
                setLoadingDelete(false)
                setOpenModal(false)
                panelNotification('error', { message: 'Algo salió mal', description: '' })
            }
        }

    }

    const validateDisplayRole = (
        value?: string | boolean | undefined
    ): ValidDisplayRoles | undefined => {
        const strRole = ValidDisplayRoles[value as keyof typeof ValidDisplayRoles];
        return strRole;
    };

    const customRenderItem = ({
        name,
        id,
        role,
        officeName,
        officeId,
        picture,
        email
    }: Record<string, string | boolean | undefined>) => (
        <List.Item
            className="list-item"
            actions={[
                <Link to={`/${ValidPath.users}/update/${id}`} key="list-loadmore-edit" className="no-text-underline">
                    <span>
                        <Icons icon={validateIconType("edit")} />
                    </span>
                    <span className="capitalize">Editar</span>
                </Link>,
                <Link to='#' className="no-text-underline" onClick={() => handleDeleteUser(id)}>
                    <Text type='danger' >
                        <span>
                            <Icons icon={validateIconType("delete")} />
                        </span>
                        <span>Eliminar</span>
                    </Text>
                </Link>,
            ]}
        >
            <List.Item.Meta
                avatar={<Avatar src={picture} />}
                title={
                    <Link
                        to={`/${ValidPath.users}/update/${id}`}
                        key="list-loadmore-edit"
                        className="users-list-link no-text-underline"
                    >
                        {name}
                        <span className="links-divider">-</span>
                        <Text italic>({email})</Text>
                    </Link>
                }
                description={
                    <div>
                        <Link
                            to={`/${ValidPath.users}/update/${id}`}
                            key="list-loadmore-edit"
                            className="users-list-link no-text-underline"
                        >
                            <span>{validateDisplayRole(role)}</span>
                        </Link>
                        {!officeName || !officeId ? (
                            <></>
                        ) : (
                            <>
                                <span className="links-divider">-</span>
                                <Link
                                    to={`/${ValidPath.offices}/update/${officeId}`}
                                    key="list-loadmore-edit"
                                    className="users-list-link no-text-underline"
                                >
                                    {officeName}
                                </Link>

                            </>
                        )}
                    </div>
                }
            />
        </List.Item>
    );

    return (
        <>
            <PaginatedList
                listData={listData}
                configData={usersConfigData}
                loading={loading}
                customRenderItem={customRenderItem}
                handleNavigationClick={(to) => {
                    history.push(`/${ValidPath.users}${to}`);
                }}
            />

            <Modal
                visible={openModal}
                title="Eliminar usuario"
                okText={'Eliminar'}
                okButtonProps={{ danger: true, type: 'ghost', loading: loadingDelete }}
                cancelText='Cerrar'
                onOk={() => handleDeleteUser()}
                onCancel={() => setOpenModal(false)}
            >
                <p>¿Confirma eliminar el usuario?</p>
            </Modal>
        </>
    );
};

export default Users;
