import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import OfficesSwitch from "../../components/Offices/OfficesSwitch";

const Offices = (): ReactElement => {
  return (
    <Layout>
      <OfficesSwitch />
    </Layout>
  );
};

export default Offices;
