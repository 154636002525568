export const getCleanedInput = (string:string) => {
    let newString = string;
    const specialChars = '!@#$^&%*()+=-[]/{}|:<>?,.';
    for (let i = 0; i < specialChars.length; i += 1) {
      newString = string.replace(new RegExp(`\\${specialChars[i]}`, 'gi'), '');
    }
  
    newString = string.replace(/´/gi, '');
    newString = string.replace(/á/gi, 'a');
    newString = string.replace(/é/gi, 'e');
    newString = string.replace(/í/gi, 'i');
    newString = string.replace(/ó/gi, 'o');
    newString = string.replace(/ú/gi, 'u');
    return newString;
  };