import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ValidPath } from "../../../RouterSwitch";
import UsersForm from "../UsersForm";
import List from "../List";

const UsersSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={`/${ValidPath.users}/create`} component={UsersForm} />
      <Route
        path={`/${ValidPath.users}/update/:userId`}
        component={UsersForm}
      />
      <Route path={`/${ValidPath.users}`} component={List} />
      <Redirect from="*" to={`/${ValidPath.users}`} />
    </Switch>
  );
};

export default UsersSwitch;
