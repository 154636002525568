import { FormConfigData as FormConfigDataBase } from "../formConfigTypes";
import { FetchService } from "../FetchService";
import mockData from "./mockUsersFormConfig.json";
import { FormData } from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";

interface FormConfigData {
  config: FormConfigDataBase["config"];
  inputs: {
    section: FormData["section"];
    required: FormData["inputs"];
    role: FormData["inputs"];
    agent: FormData["inputs"];
    admin?: FormData["inputs"];
    superadmin?: FormData["inputs"];
    travel_manager: FormData["inputs"];
    traveller: FormData["inputs"];
  };
  warnings: FormConfigDataBase["warnings"];
}

interface Response {
  error?: boolean;
  data?: FormConfigData;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getUsersFormConfig(): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + "/users/form-config";
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
