import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../../store/auth";
import Login from "./Login";

const AuthContainer: FunctionComponent = ({ children }): JSX.Element => {
  const mapState = (state: AuthState): boolean => state.auth.isAuthenticated;
  const isAuthenticated = useSelector(mapState);
  return <>{isAuthenticated ? children : <Login />}</>;
};

export default AuthContainer;
