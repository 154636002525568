import { FetchService } from "../FetchService";
import mockData from "./mockTravellerById.json";
import { travellerURI } from "./uri";

interface Traveller {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  landline: string;
  language: string;
  dni: string;
  dniExpirationDate: string;
  dniIssueDate: string;
  passport: string;
  passportExpirationDate: string;
  passportIssueDate: string;
  company: string;
  department: string;
  ceco1: string;
  ceco2: string;
  amadeusID: string;
  familyNumberExist: boolean;
  familyNumber: string;
  community: string;
  regime: string;
  resident: boolean;
  region: string;
  locality: string;
}

interface Response {
  error?: boolean;
  data?: Traveller;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getTravellerById(travellerId: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + travellerURI + `/form/${travellerId}`;
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
