import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import CompaniesSwitch from "../../components/Companies/CompaniesSwitch";

const Companies = (): ReactElement => {
  return (
    <Layout>
      <CompaniesSwitch />
    </Layout>
  );
};

export default Companies;
