import { FetchService } from "../FetchService";
import mockData from "./mockOfficeById.json";

interface Office {
  id: string;
  name: string;
  address: string;
  phone: string;
  timeout: number;
  email: number;
  signature: number;
  providers: {
    clave: string;
    proveedor: string;
    url: string;
  }[];
}

interface Response {
  error?: boolean;
  data?: Office;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getOfficeById(id: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + "/office/form/" + id;
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
