import React, { FunctionComponent } from "react";
import { Form } from "@triporate/triporate-design-system";
import { InputDataProps } from "../interface";

const FormItem: FunctionComponent<InputDataProps> = ({
  data,
  children,
  ...props
}) => {
  return (
    <Form.Item
      {...props}
      colon={false}
      label={data.label}
      name={data.name}
      rules={data.rules}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
