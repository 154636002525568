import { FetchService } from "../FetchService";


interface UsersData {
    config: UsersConfig;
    list: User[];
}

interface UsersConfig {
    list: ConfigItem;
    pageHeader: ConfigItem;
}

interface ConfigItem {
    title: string;
    button: string;
    text?: string;
}

interface User {
    name: string;
    id: string;
    email: string;
    role: string;
    officeName?: string;
    officeId?: string;
}

interface Response {
    error?: boolean;
    data?: UsersData;
}


export async function deleteUser(userId: string): Promise<any>  {

    const fetchService = new FetchService();

    const url = fetchService.baseUrl + `/users/${userId}`;
    const response = await fetchService.delete(url);

    return response;
    
}