import React, { FunctionComponent, useEffect, useState } from "react";
import {
  PageHeader,
  FormGenerator,
  SpinnerInContainer,
  Typography,
} from "@triporate/triporate-design-system";
import { FormData } from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";
import {
  getUniverseFormConfig,
  getUniverse,
  updateUniverse,
} from "../../../services/Universe";
import { getSelectOptions } from "../../../services/SelectOptions";
import { panelNotification } from "../../../utils/panelNotification";
import { uploadAsset } from "../../../services/Assets";
const { Paragraph } = Typography;

const UniverseForm: FunctionComponent = () => {
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formInputsConfig, setFormInputsConfig] = useState<FormData[]>([]);
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [sectionTitles, setSectionTitles] = useState({
    update: "",
    subtitle: "",
  });

  const [saveBtnText, setSaveBtnText] = useState("");

  useEffect(() => {
    let isMounted = true;
    const handleUniverseFormConfigFetch = async () => {
      setLoadingConfig(true);
      const { data } = await getUniverseFormConfig();

      if (!isMounted) {
        return;
      }

      if (data) {
        setFormInputsConfig(data.inputs);
        setSectionTitles((prevState) => ({
          ...prevState,
          ...data.config.title,
        }));
        setSaveBtnText(data.config.saveButton);
      }
      setLoadingConfig(false);
    };
    handleUniverseFormConfigFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async () => {
      setLoadingInitialValues(true);
      const { data } = await getUniverse();

      if (!isMounted) {
        return;
      }

      if (data) {
        const validInitialValues = data as unknown as Record<string, unknown>;
        setInitialValues(validInitialValues);
      }
      setLoadingInitialValues(false);
    };
    handleFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleFormSubmit = async (values: Record<string, unknown>) => {
    setLoadingSubmit(true);
    const body = JSON.stringify(values);
    const { error, data } = await updateUniverse(body);

    setLoadingSubmit(false);
    if (!error) {
      panelNotification("success", data);
    } else {
      panelNotification("error", data);
    }
  };

  if (loadingConfig || loadingInitialValues) {
    return <SpinnerInContainer />;
  }

  return (
    <>
      <PageHeader title={sectionTitles.update}>
        <Paragraph>{sectionTitles.subtitle}</Paragraph>
      </PageHeader>

      <FormGenerator
        submitButtonLabel={saveBtnText}
        formData={formInputsConfig}
        getSelectOptions={getSelectOptions}
        initialValues={initialValues}
        onFinish={handleFormSubmit}
        loading={loadingSubmit}
        uploadAsset={uploadAsset}
      />
    </>
  );
};

export default UniverseForm;
