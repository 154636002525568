import { ModalWelcomeData } from "../../components/ModalWelcome/types";
import { FetchService } from "../FetchService";

interface FailedUpsert {
  message: string;
  description: string;
  modal?: ModalWelcomeData;
}

interface Response {
  error?: boolean;
  data: FailedUpsert;
}

export async function upsertUser(
  body: string,
  id: string | null
): Promise<Response> {
  const fetchService = new FetchService();
  let url = fetchService.baseUrl + "/users/form";

  if (id) {
    url += `/${id}`;

    const { error, data } = await fetchService.put(url, body);
    const castedData = data as FailedUpsert;
    return { error, data: castedData };
  }

  const { error, data } = await fetchService.post(url, body);
  const castedData = data as FailedUpsert;
  return { error, data: castedData };
}
