import { FunctionComponent, useEffect, useState } from "react";
import {
    AutoComplete,
    Button,
    Modal,
    TripoCard,
} from "@triporate/triporate-design-system";
import "../style.scss";
import { getHotelOptions } from "../../../services/Companies/getHotelOptions";
import {
    HotelPreferencesInterface,
    getCompanyHotelPreferences,
} from "../../../services/Companies/getCompanyHotelPreferences";
import { deleteCompanyHotelPreference } from "../../../services/Companies/deleteCompanyHotelPreference";
import { addCompanyHotelPreference } from "../../../services/Companies/addCompanyHotelPreference";
import {VALID_ROLES_WRITE_TRAVEL_POLICIES } from "../../../utils/constants";

type HotelPreferencesProps = {
    slug: string | null;
    role: string;
};

const HotelPreferences: FunctionComponent<HotelPreferencesProps> = ({
    slug = null,
    role,
}) => {
    //------------------STATES---------------------//
    const [hotelName, setHotelName] = useState<string>("");
    const [optionsHotel, setOptionsHotel] = useState<
        { value: string; label: string }[]
    >([]);
    const [favouriteList, setFavouriteList] = useState<
        HotelPreferencesInterface[]
    >([]);
    const [blackList, setBlackList] = useState<HotelPreferencesInterface[]>([]);
    const [hotelId, setHotelId] = useState<string>("");
    const [hotelNameBlackList, setHotelNameBlackList] = useState<string>("");
    const [hotelIdBlackList, setHotelIdBlackList] = useState<string>("");
    const [isShowModalConfirmation, setIsShowModalConfirmation] =
        useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>("");

    //---------------------EFFECTS---------------------------//

    useEffect(() => {
        if (slug) {
            getHotelPreferences(slug);
        }
    }, [slug]);

    //---------------------GET DATA---------------------------//

    const getHotelPreferences = async (slug: string) => {
        const { data } = await getCompanyHotelPreferences(slug);
        if (data) {
            handleFilterData(data);
        }
    };

    //---------------------DELETE DATA---------------------------//
    const deleteHotelPreference = async () => {
        if (slug) {
            const { data } = await deleteCompanyHotelPreference(
                slug,
                idToDelete
            );
            if (data) {
                handleFilterData(data);
                setIdToDelete("");
                setIsShowModalConfirmation(false);
            }
        }
    };

    //------------------HANDLERS---------------------//
    const handleOnSelectHotel = (data: string, type: string) => {
        const value = data;
        if (value !== null && value !== "") {
            const hotel = optionsHotel.find(
                (option: any) => option.value === data
            );
            if (hotel) {
                if (type === "FAVOURITE") {
                    setHotelName(hotel.label);
                    setHotelId(hotel.value);
                } else {
                    setHotelNameBlackList(hotel.label);
                    setHotelIdBlackList(hotel.value);
                }
            }
        }
    };

    const handleChangeHotelLabel = async (event: string, type: string) => {
        if (type === "FAVOURITE") {
            setHotelName(event);
        } else {
            setHotelNameBlackList(event);
        }
    };

    const handleSearchHotel = async (text: string) => {
        const { data } = await getHotelOptions(text);
        if (data) {
            const codes: any = [];
            data.forEach((code: any) => {
                let object = {};

                if (code.type !== "city") {
                    object = {
                        label: `🏢 ${code.name}, ${code.city}, ${code.country}`,
                        value: code.place_id,
                    };

                    codes.push(object);
                }
            });

            setOptionsHotel(codes);
        }
    };

    const handleDeleteHotel = (id: string) => {
        setIdToDelete(id);
        setIsShowModalConfirmation(true);
    };

    const handleClickAddHotelPreference = async (type: string) => {
        if (slug) {
            const id = type === "FAVOURITE" ? hotelId : hotelIdBlackList;
            const { data } = await addCompanyHotelPreference(slug, id, type);
            if (data) {
                handleFilterData(data);
                setOptionsHotel([]);
                if (type === "FAVOURITE") {
                    setHotelName("");
                    setHotelId("");
                } else {
                    setHotelNameBlackList("");
                    setHotelIdBlackList("");
                }
            }
        }
    };

    const handleFilterData = (data: HotelPreferencesInterface[]) => {
        const favouriteList = data.filter((item) => item.type === "FAVOURITE");
        const blackList = data.filter((item) => item.type === "BLACKLIST");

        setFavouriteList(favouriteList);
        setBlackList(blackList);
    };

    return (
        <>
            <TripoCard>
                <div className="card">
                    <h1 className="title">Selección de alojamiento</h1>
                    <TripoCard>
                        <div className="title-margin">
                            <h2 className="subtitle">Hoteles preferidos</h2>
                        </div>
                        {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                            <div className="section-inputs-points-of-interest">
                                <div className="input">
                                    <span>Hotel</span>
                                    <AutoComplete
                                        value={hotelName}
                                        onSearch={(text) =>
                                            handleSearchHotel(text)
                                        }
                                        onChange={(event) =>
                                            handleChangeHotelLabel(
                                                event,
                                                "FAVOURITE"
                                            )
                                        }
                                        onSelect={(event: string) =>
                                            handleOnSelectHotel(
                                                event,
                                                "FAVOURITE"
                                            )
                                        }
                                        style={{ width: 300 }}
                                        options={optionsHotel}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        handleClickAddHotelPreference(
                                            "FAVOURITE"
                                        )
                                    }
                                    disabled={hotelId.length < 1}
                                >
                                    Agregar
                                </Button>
                            </div>
                        )}

                        <div className="card-margin card-grouped">
                            {favouriteList.length > 0 ? (
                                <>
                                    {favouriteList.map((item) => (
                                        <div
                                            className="card-list"
                                            key={item._id}
                                        >
                                            <div>
                                                <span>{item.hotelDetails.name}</span>
                                            </div>
                                            <div>
                                                <span>{item.hotelDetails.city}</span>
                                            </div>
                                            {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                                                <div>
                                                    <i
                                                        className="icon-trash-alt-regular cursor-pointer"
                                                        onClick={() =>
                                                            handleDeleteHotel(
                                                                item._id
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <h2 className="subtitle title-margin">
                                    No hay hoteles registrados
                                </h2>
                            )}
                        </div>
                    </TripoCard>
                    <TripoCard>
                        <div className="title-margin">
                            <h2 className="subtitle">
                                Hoteles no recomendados
                            </h2>
                        </div>
                        {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                            <div className="section-inputs-points-of-interest">
                                <div className="input">
                                    <span>Hotel</span>
                                    <AutoComplete
                                        value={hotelNameBlackList}
                                        onSearch={(text) =>
                                            handleSearchHotel(text)
                                        }
                                        onChange={(event) =>
                                            handleChangeHotelLabel(
                                                event,
                                                "BLACKLIST"
                                            )
                                        }
                                        onSelect={(event: string) =>
                                            handleOnSelectHotel(
                                                event,
                                                "BLACKLIST"
                                            )
                                        }
                                        style={{ width: 300 }}
                                        options={optionsHotel}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        handleClickAddHotelPreference(
                                            "BLACKLIST"
                                        )
                                    }
                                    disabled={hotelIdBlackList.length < 1}
                                >
                                    Agregar
                                </Button>
                            </div>
                        )}

                        <div className="card-margin card-grouped">
                            {blackList.length > 0 ? (
                                <>
                                    {blackList.map((item) => (
                                        <div
                                            className="card-list"
                                            key={item._id}
                                        >
                                            <div>
                                                <span>{item.hotelDetails.name}</span>
                                            </div>
                                            <div>
                                                <span>{item.hotelDetails.city}</span>
                                            </div>
                                            {VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                                                <div>
                                                    <i
                                                        className="icon-trash-alt-regular cursor-pointer"
                                                        onClick={() =>
                                                            handleDeleteHotel(
                                                                item._id
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <h2 className="subtitle title-margin">
                                    No hay hoteles registrados
                                </h2>
                            )}
                        </div>
                    </TripoCard>
                </div>
            </TripoCard>
            <Modal
                visible={isShowModalConfirmation}
                onOk={deleteHotelPreference}
                okText={"Aceptar"}
                onCancel={() => {
                    setIsShowModalConfirmation(false);
                }}
                cancelText={"Cancelar"}
            >
                <div>
                    <h2>Selección de alojamiento</h2>
                    <span>¿Esta seguro de eliminar este hotel?</span>
                </div>
            </Modal>
        </>
    );
};

export default HotelPreferences;
