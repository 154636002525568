import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import UsersSwitch from "../../components/Users/UsersSwitch";

const Users = (): ReactElement => {
  return (
    <Layout>
      <UsersSwitch />
    </Layout>
  );
};

export default Users;
