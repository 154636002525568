import { FetchService } from "../FetchService";
import { Budget } from "./getCompanyBudgets";
import { dashboardURI } from "./uri";



interface Response {
    error?: boolean;
    data?: Budget;
}

export async function deleteCompanyBudgetHotel(slug: string, id:string): Promise<Response> {
    const fetchService = new FetchService();

    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/budget/hotel/" + id;

    const { data, error } = await fetchService.delete(url);
    if (!error) {
        const castedData = data as Response["data"];
        return { data: castedData };
    }

    return { error };
}
