import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import UniverseSwitch from "../../components/Universe/UniverseSwitch";

const Universe = (): ReactElement => {
  return (
    <Layout>
      <UniverseSwitch />
    </Layout>
  );
};

export default Universe;
