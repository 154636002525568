import {
  ActionTypes,
  State,
  SET_ADDITIONAL_CONFIG,
  SET_EMBEDDED_MODE_CONFIG,
} from "./types";

export const handleSetAdditionalConfig = (payload: State): ActionTypes => ({
  type: SET_ADDITIONAL_CONFIG,
  payload,
});

export const handleSetEmbeddedModeConfig = (): ActionTypes => ({
  type: SET_EMBEDDED_MODE_CONFIG,
});
