import React, { FunctionComponent } from "react";
import { Input } from "@triporate/triporate-design-system";
import { InputDataProps } from "../interface";
import FormItem from "./FormItem";

const TextInput: FunctionComponent<InputDataProps> = ({ data, ...props }) => {
  return (
    <FormItem {...props} data={data}>
      <Input />
    </FormItem>
  );
};

export default TextInput;
