import {
  State,
  ActionTypes,
  SET_ADDITIONAL_CONFIG,
  SET_EMBEDDED_MODE_CONFIG,
} from "./types";

export const additionalConfigReducer = (
  state = {
    loadedAdditionalConfig: false,
    tab: {
      favicon: "",
    },
    header: {
      user: {
        initials: "",
        fullName: "",
      },
    },
    sidebar: {
      agency: {
        logo: "",
        name: "",
      },
      items: {},
    },
  },
  action: ActionTypes
): State => {
  switch (action.type) {
    case SET_ADDITIONAL_CONFIG:
      return action.payload;
    case SET_EMBEDDED_MODE_CONFIG:
      return { ...state, loadedAdditionalConfig: true };
    default:
      return state;
  }
};
