import { FetchService } from "../FetchService";
import { dashboardURI } from "./uri";

export interface Budget {
    company: Company;
    traveller: null;
    _id: string;
    hotel: Hotel[];
    global: null;
    __v?: number;
}

interface Company {
    paymentFlexibility: PaymentFlexibility;
    deleted: boolean;
    accountManager: null;
    travellersCantBookForOthers: boolean;
    isSBTEnabled: boolean;
    keywords: any[];
    _id: string;
    __v: number;
    active: boolean;
    address: string;
    agentId: number;
    cif: string;
    contact_name: string;
    contact_phone: string;
    createdAt: Date;
    domain: string;
    domain_assignment: boolean;
    logo: string;
    name: string;
    office: string;
    shared: boolean;
    slug: string;
    universe: string;
    updatedAt: Date;
    pois: any[];
    billingFulfillmentConfig: any[];
    id: string;
}

interface PaymentFlexibility {
    enable: boolean;
}

interface Hotel {
    deviation: number;
    _id: string;
    region: Region;
    budget: number;
}
interface Region {
    country?: string;
    city?: string;
    breakfast: boolean;
    cancelable: boolean;
}

interface Response {
    error?: boolean;
    data?: Budget[];
}

export async function getCompanyBudgets(slug: string): Promise<Response> {
    const fetchService = new FetchService();

    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/budgets";

    const { data, error } = await fetchService.get(url);

    if (!error) {
        const castedData = data as Response["data"];
        return { data: castedData };
    }

    return { error };
}
