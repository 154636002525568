import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import UniverseForm from "../UniverseForm";
import { ValidPath } from "../../../RouterSwitch";

const UniverseSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={`/${ValidPath.config}`} component={UniverseForm} />
      <Redirect from="*" to={`/${ValidPath.config}`} />
    </Switch>
  );
};

export default UniverseSwitch;
