import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { ConfigState } from "../../../store/config";
import ConfigLoader from "./ConfigLoader";

const ConfigContainer: FunctionComponent = ({ children }): JSX.Element => {
  const mapState = (state: ConfigState): boolean => state.config.loadedConfig;
  const loadedConfig = useSelector(mapState);

  return <>{loadedConfig ? children : <ConfigLoader />}</>;
};

export default ConfigContainer;
