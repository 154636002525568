import { FetchService } from "../FetchService";
import { dashboardURI } from "./uri";

export interface BodyRequestPoi{
    location:         Location;
    status:           string;
    createdFrom:      string;
    alternativeNames: any[];
    priority:         string;
    name:             string;
    type:             string;
    companySlug:      string;
    address:          string;
    latitude:         number;
    longitude:        number;
    zone_cod:         string;
    cod:              number;
    zone_name:        string;
    state_name:       string;
    country_name:     string;
    country_iso:      string;
}

export interface Location {
    type:        string;
    coordinates: number[];
}


interface Response {
    error?: boolean;
    data?: any;
  }

export async function addPointOfInterest(slug: string, body:any
): Promise<Response> {
    const fetchService = new FetchService();
  
    const url = fetchService.baseUrl + dashboardURI + "/" + slug + "/poi";
  
    const { data, error } = await fetchService.post(url, body);
  
    if (!error) {
      const castedData = data as Response["data"];
      return { data: castedData };
    }
  
    return { error };
  }