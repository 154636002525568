import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { ValidPath } from "../../../RouterSwitch";
import { panelNotification } from "../../../utils/panelNotification";
import TravellersBaseForm from "../TravellersBaseForm";
import { SubmitResultHandler } from "../../../hooks/useTravellerForm/types";

const TravellersAgentForm: FunctionComponent = () => {
  const history = useHistory();

  const onSuccess: SubmitResultHandler = (data) => {
    panelNotification("success", data);
    history.push(`/${ValidPath.travellers}`);
  };

  const onError: SubmitResultHandler = (data) => {
    panelNotification("error", data);
  };

  return (
    <TravellersBaseForm agentsMode onSuccess={onSuccess} onError={onError} />
  );
};

export default TravellersAgentForm;
