import { FetchService } from "../FetchService";

interface Response {
    error?: boolean;
    data?: any;
}

export async function getHotelOptions(inputValue: string): Promise<Response> {
    
    const array:any = []

    if (Boolean(inputValue) && inputValue.length > 2)  {
        const fetchService = new FetchService();

        const url = `${fetchService.baseUrl}/autocomplete?location=${inputValue}&type=hotel`;

        const { data, error } = await fetchService.get(url);

        if (!error) {
            const castedData = data as Response["data"];
            return { data: castedData };
        }

        return { error };
    } else {
        return array
    }
}
