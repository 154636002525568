import { FetchService } from "../FetchService";
import { billingFulfillmentURI } from "./uri";
import { FormConfig } from "./interface";

export interface BillingFulfillmentFormConfigResponse {
  error?: boolean;
  data?: {
    offerId: string;
    billingFulfillmentEnabled: boolean;
    billingFulfilled: boolean;
    formConfig: FormConfig;
    formValues: Record<string, unknown>;
  };
}

export interface BillingFulfillmentFormConfigQuery {
  offerId?: string;
  tripId?: string;
}

const generateQuery = ({
  offerId,
  tripId,
}: BillingFulfillmentFormConfigQuery) => {
  if (offerId) return `?offerId=${offerId}`;
  if (tripId) return `?tripId=${tripId}`;
  return "";
};

export async function getBillingFulfillmentFormConfig({
  offerId,
  tripId,
}: BillingFulfillmentFormConfigQuery): Promise<BillingFulfillmentFormConfigResponse> {
  const fetchService = new FetchService();

  const query = generateQuery({
    offerId,
    tripId,
  });
  const url = fetchService.baseUrl + billingFulfillmentURI + query;

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as BillingFulfillmentFormConfigResponse["data"];
    return { data: castedData };
  }

  return { error };
}
