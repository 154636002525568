import React, { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "@triporate/triporate-design-system";
import { useGoogleTagManager } from "../../../hooks";
import { getAdditionalConfig } from "../../../services/AdditionalConfig";
import {
  AdditionalConfigState,
  handleSetEmbeddedModeConfig,
} from "../../../store/additionalConfig";

const AdditionalConfigContainer: FunctionComponent = ({
  children,
}): JSX.Element => {
  const dispatch = useDispatch();
  const mapState = (state: AdditionalConfigState): boolean =>
    state.additionalConfig.loadedAdditionalConfig;
  const loadedAdditionalConfig = useSelector(mapState);

  useGoogleTagManager();

  useEffect(() => {
    const isInIframe = () => parent !== window;

    if (isInIframe()) {
      dispatch(handleSetEmbeddedModeConfig());
    } else {
      getAdditionalConfig();
    }
  }, [dispatch]);

  return <>{loadedAdditionalConfig ? children : <Spinner />} </>;
};

export default AdditionalConfigContainer;
