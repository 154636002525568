export const getDefaultSelecteKey = ({
  pathList,
  pathName,
}: {
  pathList: string[];
  pathName: string;
}): string[] => {
  const defaultSelectedKeys = [];
  const currentPath = pathName.split("/")[1];
  const initialLocation = pathList.find((path) => path === currentPath);
  const defaultPath = pathList[0];

  if (initialLocation) {
    defaultSelectedKeys.push(`/${initialLocation}`);
  } else if (defaultPath) {
    defaultSelectedKeys.push(`/${defaultPath}`);
  }

  return defaultSelectedKeys;
};
