import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import CompaniesForm from "../CompaniesForm";
import List from "../CompaniesList";
import { ValidPath } from "../../../RouterSwitch";

const CompaniesSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={`/${ValidPath.companies}/create`}
        component={CompaniesForm}
      />
      <Route
        path={`/${ValidPath.companies}/update/:companyId`}
        component={CompaniesForm}
      />
      <Route path={`/${ValidPath.companies}`} component={List} />
      <Redirect from="*" to={`/${ValidPath.companies}`} />
    </Switch>
  );
};

export default CompaniesSwitch;
