import React, { FunctionComponent } from "react";
import { Modal } from "@triporate/triporate-design-system";
import { upsertTravellerEnable } from "../../../services/Travellers";
import { panelNotification } from "../../../utils/panelNotification";
import { TravellersConfigData } from "./type";

interface EnableTravellerModalProps {
  travellersData?: {
    id: string;
    enable: boolean;
    name: string;
  };
  config?: TravellersConfigData["enableModal"];
  cleanTravellersData: () => void;
  updateTravellerListData: (id: string, enable: boolean) => void;
}

const EnableTravellerModal: FunctionComponent<EnableTravellerModalProps> = ({
  travellersData,
  config,
  cleanTravellersData,
  updateTravellerListData,
}): JSX.Element => {
  const handleFormSubmit = async () => {
    if (!travellersData) return;

    const { id, enable } = travellersData;
    const updatedEnable = !enable;
    const body = JSON.stringify({ enable: updatedEnable });
    cleanTravellersData();
    updateTravellerListData(id, updatedEnable);
    const { error, data } = await upsertTravellerEnable(body, id);
    if (!error) {
      panelNotification("success", data);
    } else {
      panelNotification("error", data);
      updateTravellerListData(id, enable);
    }
  };

  const formatMessage = () => {
    if (!travellersData || !config) return "";
    const { message, actions, patterns } = config;
    const { name, enable } = travellersData;
    const currentAction = actions[`${enable}`];
    return message
      .replace(patterns.action, currentAction)
      .replace(patterns.name, name);
  };

  return (
    <Modal
      visible={!!travellersData}
      onOk={handleFormSubmit}
      okText={config?.okText}
      onCancel={cleanTravellersData}
      cancelText={config?.cancelText}
      closable={false}
    >
      <span>{formatMessage()}</span>
    </Modal>
  );
};

export default EnableTravellerModal;
