import { getCleanedInput } from "../../utils/cleanedInput";
import { FetchService } from "../FetchService";

export interface AddressOptions {
    description:           string;
    matched_substrings:    MatchedSubstring[];
    place_id:              string;
    reference:             string;
    structured_formatting: StructuredFormatting;
    terms:                 Term[];
    types:                 string[];
}

interface MatchedSubstring {
    length: number;
    offset: number;
}

interface StructuredFormatting {
    main_text:                    string;
    main_text_matched_substrings: MatchedSubstring[];
    secondary_text:               string;
}

interface Term {
    offset: number;
    value:  string;
}

interface Response {
    error?: boolean;
    data?: AddressOptions[];
}

export async function getAddressOptions(inputValue: string): Promise<Response> {
    const cleanInputValue = getCleanedInput(inputValue);
    const array:any = []

    if (cleanInputValue !== "" && cleanInputValue.length > 2) {
        const fetchService = new FetchService();

        const url = `${fetchService.baseUrl}/autocomplete/autocomplete-google?q=${cleanInputValue}`;

        const { data, error } = await fetchService.get(url);

        if (!error) {
            const castedData = data as Response["data"];
            return { data: castedData };
        }

        return { error };
    } else {
        return array
    }
}
