import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ValidPath } from "../../../RouterSwitch";
import List from "../List";
import TravellersForm from "../TravellersForm";
import TravellersAgentForm from "../TravellersAgentForm";

const TravellersSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={`/${ValidPath.travellers}/create`}
        component={TravellersForm}
      />
      <Route
        path={`/${ValidPath.travellers}/update/:travellerId`}
        component={TravellersForm}
      />
      <Route
        path={`/${ValidPath.travellers}/create-from-agents/:travellerId`}
        component={TravellersAgentForm}
      />
      <Route path={`/${ValidPath.travellers}`} component={List} />
      <Redirect from="*" to={`/${ValidPath.travellers}`} />
    </Switch>
  );
};

export default TravellersSwitch;
