import { FetchService } from "../FetchService";

export default class AuthorizationService extends FetchService {
  public redirectToLogin(): void {
    const loginUrl = this.loginUrl;
    if (loginUrl) window.location.href = loginUrl;
  }

  public authValidation = async (): Promise<boolean> => {
    if (this.useFakeApi) return true;
    return !!this.getToken();
  };

  public logout = (): void => {
    this.handleLogout();
  };
}
