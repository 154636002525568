import { FetchService } from "../FetchService";
import mockData from "./mockUserById.json";

interface User {
  id: string;
  name: string;
  lastName: string;
  email: string;
  password: string;
  dni: string;
  office: string;
  ccodLoginName: string;
  ccodPhone: string;
  phone: string;
  company: string;
  role: string;
}

interface Response {
  error?: boolean;
  data?: User;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getUserById(userId: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + `/users/form/${userId}`;
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
