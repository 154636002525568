import React, { FunctionComponent, useState } from "react";
import { Button, Modal } from "@triporate/triporate-design-system";
import { SubmitResultHandler } from "../../hooks/useTravellerForm/types";
import { ModalData } from "./types";
import { sendWelcomeEmail } from "../../services/Users";

interface ModalWelcomeProps {
  modalData: ModalData;
  onSuccess?: SubmitResultHandler;
  onError?: SubmitResultHandler;
}

const ModalWelcome: FunctionComponent<ModalWelcomeProps> = ({
  modalData,
  onSuccess,
  onError,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(!!modalData.modal);
  const email = { email: modalData.modal.email };
  const UserId = { _id: modalData.modal._id };
  const upsertResponse = modalData.response;

  const handleSendEmail = async () => {
    const body = JSON.stringify({ ...email, ...UserId });
    const { error, data } = await sendWelcomeEmail(body);
    if (!error) {
      onSuccess?.(data);
    } else {
      onError?.(data);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    if (!upsertResponse?.error) onSuccess?.(upsertResponse?.data);
    else onError?.(upsertResponse?.data);

    setIsModalOpen(false);
  };

  return (
    <Modal
      title={modalData?.modal.btnLabel}
      visible={isModalOpen}
      onOk={handleSendEmail}
      onCancel={handleCancel}
      closable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {modalData?.modal.btnCancel}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSendEmail}>
          {modalData?.modal.btnSend}
        </Button>,
      ]}
    >
      <p>{modalData?.modal.text}</p>
    </Modal>
  );
};

export default ModalWelcome;
