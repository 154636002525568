import { FunctionComponent, useEffect, useState } from "react";
import PointsOfInterest from "../PointsOfInterest";
import BudgetForAccommodations from "../BudgetForAccommodations";
import HotelsPreferences from "../HotelsPreferences";
import "../style.scss";

type TravelPoliciesProps = {
    slug: string | null;
    role: string
};

const TravelPolicies: FunctionComponent<TravelPoliciesProps> = ({
    slug = null,
    role
}) => {
    return (
        <div className="sector-travel-policies">
            <PointsOfInterest slug={slug} role={role} />
            <BudgetForAccommodations slug={slug} role={role} />
            <HotelsPreferences slug={slug} role={role} />
        </div>
    );
};

export default TravelPolicies;
