import { FormConfigData } from "../formConfigTypes";
import { FetchService } from "../FetchService";
import mockData from "./mockOfficesFormConfig.json";

interface Response {
  error?: boolean;
  data?: FormConfigData;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getOfficesFormConfig(): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + "/office/form-config";
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
