import React, { FunctionComponent } from "react";
import { Button } from "@triporate/triporate-design-system";
import { Link, useHistory } from 'react-router-dom';
import { ValidPath } from "../../../RouterSwitch";

type GoBackHeaderButtonProps = {
  text: string;
};

const GoBackHeaderButton: FunctionComponent<GoBackHeaderButtonProps> = ({
  text,
}) => {

  const history = useHistory()

  return (
    <Button>
      <Link className="no-text-underline" to={'#'}  onClick={()=> history.goBack()}>
        {text}
      </Link>
    </Button>
  );
};

export default GoBackHeaderButton;
