import React from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ConfigProvider, esLocale } from "@triporate/triporate-design-system";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import "@triporate/triporate-design-system/dist/css/fontello.css";
import i18n from "./i18n";
import { store } from "./store";
import "./App.scss";
import RouterSwitch from "./RouterSwitch";
import {
  AdditionalConfigContainer,
  AuthContainer,
  ConfigContainer,
  UniverseStyleContainer,
} from "./components";

const App = (): JSX.Element => {
  return (
    <ConfigProvider locale={esLocale}>
      <BrowserRouter>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <ConfigContainer>
              <AuthContainer>
                <AdditionalConfigContainer>
                  <UniverseStyleContainer>
                    <RouterSwitch />
                  </UniverseStyleContainer>
                </AdditionalConfigContainer>
              </AuthContainer>
            </ConfigContainer>
          </I18nextProvider>
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
