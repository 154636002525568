import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  PageHeader,
  FormGenerator,
  SpinnerInContainer,
} from "@triporate/triporate-design-system";
import { FormData } from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";
import { DefaultEditor, ContentEditableEvent } from "react-simple-wysiwyg";
import { useFormMode } from "../../../hooks";
import {
  getCompanyById,
  getCompanyFormConfig,
  upsertCompany,
} from "../../../services/Companies";
import { getSelectOptions } from "../../../services/SelectOptions";
import { panelNotification } from "../../../utils/panelNotification";
import { ValidPath } from "../../../RouterSwitch";
import GoBackHeaderButton from "../GoBackHeaderButton";
import { uploadAsset } from "../../../services/Assets";
import { inputValidation } from "../../../services/InputValidation";
import { FormConfigData } from "../../../services/formConfigTypes";
import TravelPolicies from "../TravelPolicies";
import { getRole } from "../../../services/Role";
const CompaniesForm: FunctionComponent = () => {
  const history = useHistory();
  const [companyId, mode] = useFormMode("companyId");
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formInputsConfig, setFormInputsConfig] = useState<FormData[]>([]);
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [sectionTitles, setSectionTitles] = useState({
    create: "",
    update: "",
  });
  const [btnText, setBtnText] = useState<string>("");
  const [saveBtnText, setSaveBtnText] = useState("");
  const [confirmationModalData, setConfirmationModalData] =
    useState<FormConfigData["config"]["confirmationModal"]>();
  const [slug, setSlug] = useState<string | null>(null);
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    let isMounted = true;
    const handleCompaniesFormConfigFetch = async () => {
      setLoadingConfig(true);
      const { data } = await getCompanyFormConfig();

      if (!isMounted) {
        return;
      }

      if (data) {
        setFormInputsConfig(data.inputs);
        setSectionTitles((prevState) => ({
          ...prevState,
          ...data.config.title,
        }));
        setBtnText((prevState) => data.config.headerButton || prevState);
        setSaveBtnText(data.config.saveButton);
        setConfirmationModalData(data.config.confirmationModal);
      }
      setLoadingConfig(false);
    };
    handleCompaniesFormConfigFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    getRoleUser();
}, []);

const getRoleUser = async () => {
  const { data } = await getRole();
  if (data) {
      setRole(data);
  }
};

  useEffect(() => {
    if (companyId) {
      handleFetch(companyId);
    } else {
      setLoadingInitialValues(false);
    }
  }, [companyId]);

  const handleFormSubmit = async (values: Record<string, unknown>) => {
    setLoadingSubmit(true);
    const body = JSON.stringify(values);
    const { error, data } = await upsertCompany(body, companyId);

    if (!error) {
      history.push(`/${ValidPath.companies}`);
      panelNotification("success", data);
    } else {
      setLoadingSubmit(false);
      panelNotification("error", data);
    }
  };

  const handleFetch = async (id: string) => {
    setLoadingInitialValues(true);
    const { data } = await getCompanyById(id);


    if (data) {
      const validInitialValues = data as unknown as Record<string, unknown>;
      setInitialValues(validInitialValues);
      if(data?.slug){
        setSlug(data.slug)
      }
    }
    setLoadingInitialValues(false);
  };

  if (loadingConfig || loadingInitialValues) {
    return <SpinnerInContainer />;
  }

  return (
    <>
      <PageHeader
        title={sectionTitles[mode]}
        extra={[
          <GoBackHeaderButton key="go-back-header-button" text={btnText} />,
        ]}
      />
      <div style={{
        display: "flex",
        gap: 4
      }}>
      <FormGenerator
        submitButtonLabel={saveBtnText}
        formData={formInputsConfig}
        initialValues={initialValues}
        onFinish={handleFormSubmit}
        loading={loadingSubmit}
        uploadAsset={uploadAsset}
        inputValidator={inputValidation}
        getSelectOptions={getSelectOptions}
        isUpdate={!!companyId}
        confirmationModalData={confirmationModalData}
        mode={mode}
        wysiwygFactory={() => ({
          component: DefaultEditor,
          onChangeHandler: (payload: unknown) => {
            const event = payload as ContentEditableEvent;
            return event?.target?.value;
          },
        })}
      />
      {
       slug && <TravelPolicies slug={slug} role={role}/>
      }
      </div>
    </>
  );
};

export default CompaniesForm;
