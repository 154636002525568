import { FunctionComponent, useEffect, useState } from "react";
import {
    AutoComplete,
    Button,
    Input,
    Modal,
    TripoCard,
} from "@triporate/triporate-design-system";
import "../style.scss";
import {
    PointOfInterest,
    getCompanySedes,
} from "../../../services/Companies/getCompanySedes";
import { deleteCompanySede } from "../../../services/Companies";
import {
    AddressOptions,
    getAddressOptions,
} from "../../../services/Companies/getAddressOptions";
import { getPoiData } from "../../../services/Companies/getPoiData";
import {
    BodyRequestPoi,
    addPointOfInterest,
} from "../../../services/Companies/addPointOfInterest";
import { VALID_ROLES_WRITE_TRAVEL_POLICIES } from "../../../utils/constants";

type PointOfInterestProps = {
    slug: string | null;
    role: string;
};

const PointsOfInterest: FunctionComponent<PointOfInterestProps> = ({
    slug = null,
    role,
}) => {
    //------------------STATES---------------------//

    const initialBodyRequestPoi = {
        location: {
            type: "Point",
            coordinates: [],
        },
        status: "ENABLED",
        createdFrom: "GOOGLE",
        alternativeNames: [],
        priority: "100",
        name: "",
        type: "OFFICE",
        companySlug: "",
        address: "",
        latitude: 0,
        longitude: 0,
        zone_cod: "",
        cod: 0,
        zone_name: "",
        state_name: "",
        country_name: "",
        country_iso: "",
    };
    const [poiList, setPoiList] = useState<PointOfInterest[]>([]);
    const [poiName, setPoiName] = useState<string>("");
    const [poiAddress, setPoiAddress] = useState<string>("");
    const [optionsAddress, setOptionAddress] = useState<
        { value: string; label: string }[]
    >([]);
    const [placeId, setPlaceId] = useState<string>("");
    const [poiData, setPoiData] = useState<BodyRequestPoi>(
        initialBodyRequestPoi
    );
    const [isShowModalConfirmation, setIsShowModalConfirmation] =
        useState<boolean>(false);

    const [idToDelete, setIdToDelete] = useState<string>("");

    //---------------------EFFECTS---------------------------//

    useEffect(() => {
        if (placeId) {
            handleGetPoiData();
        }
    }, [placeId]);

    useEffect(() => {
        if (slug) {
            getPoiList(slug);
        }
    }, [slug]);

    //---------------------GET DATA---------------------------//

    const getPoiList = async (slug: string) => {
        const { data } = await getCompanySedes(slug);
        if (data) {
            setPoiList(data);
        }
    };

    //---------------------DELETE DATA---------------------------//
    const deletePoi = async () => {
        if (slug && idToDelete !== "") {
            const { data } = await deleteCompanySede(slug, idToDelete);
            if (data) {
                const pois = data.map((poi: PointOfInterest) => ({
                    _id: poi._id,
                    name: poi.name,
                    type: poi.type,
                    address: poi.address,
                    latitude: poi.latitude,
                    longitude: poi.longitude,
                    zone_cod: poi.zone_cod,
                    cod: poi.cod,
                    zone_name: poi.zone_name,
                    state_name: poi.state_name,
                    country_name: poi.country_name,
                }));

                setPoiList(pois);
                setIdToDelete("");
                setIsShowModalConfirmation(false);
            }
        }
    };

    //------------------HANDLERS---------------------//
    const handleOnSelectAddress = (data: string) => {
        const value = data;
        const address = optionsAddress.find(
            (option: any) => option.value === data
        );
        setPoiAddress(address ? address.label : "");

        if (value !== null && value !== "") {
            setPlaceId(value);
        }
    };

    const handleChangeAddressLabel = async (event: string) => {
        setPoiAddress(event);
    };

    const handleSearchAddress = async (text: string) => {
        const { data } = await getAddressOptions(text);
        const directions: any = [];
        let filteredResponse: any = [];

        filteredResponse = data?.filter((elem) => {
            return !elem.types.includes("locality");
        });

        filteredResponse?.forEach((direction: AddressOptions) => {
            const object = {
                label: direction.description,
                value: direction.place_id,
            };
            directions.push(object);
        });

        setOptionAddress(directions);
    };

    const handleGetPoiData = async () => {
        const { data } = await getPoiData(placeId);

        if (!data.result.geometry.location) {
            return;
        }
        const { lat, lng: lon } = data.result.geometry.location;
        const zoneName = data.result.address_components[2].long_name;
        const stateName = data.result.address_components[3].long_name;
        const countryName = data.result.address_components[5]
            ? data.result.address_components[5].long_name
            : "";
        const countryIso = data.result.address_components[5]
            ? data.result.address_components[5].short_name
            : "";

        setPoiData({
            ...poiData,
            name: poiName,
            location: {
                type: "Point",
                coordinates: [lon, lat],
            },
            latitude: lat,
            longitude: lon,
            address: data.result.formatted_address,
            zone_name: zoneName,
            state_name: stateName,
            country_name: countryName,
            country_iso: countryIso,
        });
    };

    const handleClickAddPoi = async () => {
        if (slug) {
            const { data } = await addPointOfInterest(
                slug,
                JSON.stringify(poiData)
            );
            if (data) {
                setPoiAddress("");
                setPoiName("");
                setPoiData(initialBodyRequestPoi);
                setPlaceId("");
                setOptionAddress([])
                const pois = data.map((poi: PointOfInterest) => ({
                    _id: poi._id,
                    name: poi.name,
                    type: poi.type,
                    address: poi.address,
                    latitude: poi.latitude,
                    longitude: poi.longitude,
                    zone_cod: poi.zone_cod,
                    cod: poi.cod,
                    zone_name: poi.zone_name,
                    state_name: poi.state_name,
                    country_name: poi.country_name,
                }));

                setPoiList(pois);
            }
        }
    };

    const handleDeletePoi = async (id: string) => {
        setIdToDelete(id);
        setIsShowModalConfirmation(true);
    };

    return (
        <>
            <TripoCard>
                <div className="card">
                    <h1 className="title">Puntos de interés</h1>
                    { VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                        <>
                            <div>
                                <h2 className="subtitle">
                                    Nuevos puntos de interés
                                </h2>
                            </div>
                            <div className="section-inputs-points-of-interest">
                                <div className="input">
                                    <span>Nombre</span>
                                    <Input
                                        value={poiName}
                                        onChange={(event) =>
                                            setPoiName(event.target.value)
                                        }
                                    />
                                </div>

                                <div className="input">
                                    <span>Dirección</span>
                                    <AutoComplete
                                        value={poiAddress}
                                        onSearch={(text) =>
                                            handleSearchAddress(text)
                                        }
                                        onChange={(event) =>
                                            handleChangeAddressLabel(event)
                                        }
                                        onSelect={handleOnSelectAddress}
                                        style={{ width: 170 }}
                                        options={optionsAddress}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    onClick={handleClickAddPoi}
                                    disabled={
                                        poiName.length < 3 || placeId.length < 1
                                    }
                                >
                                    Agregar
                                </Button>
                            </div>
                        </>
                    )}
                </div>

                {poiList.length > 0 ? (
                    <>
                        <div className="card-margin">
                            <h2 className="subtitle title-margin">
                                Puntos registrados
                            </h2>
                        </div>
                        <div className="card-grouped">
                            {poiList.map((poi) => (
                                <div key={poi._id} className="card-list">
                                    <div>
                                        <span>{poi.name}</span>
                                    </div>
                                    <div>
                                        <span>
                                            <i className="icon-map-marker-alt-solid" />
                                            <a
                                                href={`https://www.google.com/maps/search/?api=1&query=${poi.address}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {poi.address}
                                            </a>
                                        </span>
                                    </div>

                                    { VALID_ROLES_WRITE_TRAVEL_POLICIES.includes(role) && (
                                        <div>
                                            <i
                                                className="icon-trash-alt-regular cursor-pointer"
                                                onClick={() =>
                                                    handleDeletePoi(poi._id)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="card-margin">
                        <h2 className="subtitle title-margin">
                            No hay puntos registrados
                        </h2>
                    </div>
                )}
            </TripoCard>

            <Modal
                visible={isShowModalConfirmation}
                onOk={deletePoi}
                okText={"Aceptar"}
                onCancel={() => {
                    setIsShowModalConfirmation(false);
                }}
                cancelText={"Cancelar"}
            >
                <div>
                    <h2>Puntos de interés</h2>
                    <span>¿Esta seguro de eliminar este punto de interés?</span>
                </div>
            </Modal>
        </>
    );
};

export default PointsOfInterest;
