import React, { FunctionComponent } from "react";
import { Tooltip } from "@triporate/triporate-design-system";
import { LogoutOutlined } from "@ant-design/icons";
import { AuthorizationService } from "../../../../services/Authorization";

const Logout: FunctionComponent = (): JSX.Element => {
  const handleLogout = () => {
    const AuthService = new AuthorizationService();
    AuthService.logout();
  };
  return (
    <Tooltip title="Logout" placement="left">
      <LogoutOutlined
        className="header-button logout-button"
        onClick={handleLogout}
      />
    </Tooltip>
  );
};

export default Logout;
