import React from "react";
import {
  Layout as AntLayout,
  Row,
  Col,
  Avatar,
  Typography,
} from "@triporate/triporate-design-system";
import { useSelector } from "react-redux";
import { AdditionalConfigState } from "../../../store/additionalConfig";
import Logout from "./Logout";

import "./Header.scss";
const { Header } = AntLayout;
const { Text } = Typography;

const PanelHeader = (): JSX.Element => {
  const mapState = (
    state: AdditionalConfigState
  ): AdditionalConfigState["additionalConfig"] => state.additionalConfig;
  const { header } = useSelector(mapState);

  return (
    <Header className="panel-header">
      <Row gutter={15} justify="end">
        <Col>
          <Avatar size={30}>{header?.user?.initials}</Avatar>
        </Col>
        <Col>
          <Text>{header?.user?.fullName}</Text>
        </Col>
        <Col>
          <Logout />
        </Col>
        <Col />
      </Row>
    </Header>
  );
};

export default PanelHeader;
