import React, { FunctionComponent } from "react";
import { Button } from "@triporate/triporate-design-system";
import { Link } from "react-router-dom";
import { ValidPath } from "../../../RouterSwitch";

type GoBackHeaderButtonProps = {
  text: string;
};

const GoBackHeaderButton: FunctionComponent<GoBackHeaderButtonProps> = ({
  text,
}) => {
  return (
    <Button>
      <Link className="no-text-underline" to={`/${ValidPath.companies}`}>
        {text}
      </Link>
    </Button>
  );
};

export default GoBackHeaderButton;
