import { FetchService } from "../FetchService";


interface Response {
  error?: boolean;
  data?: any;
}

export async function getRole(): Promise<Response> {
  const fetchService = new FetchService();

  const url = fetchService.baseUrl + "/admin-panel/init-config";

  const { data, error } = await fetchService.get(url);

  if (!error) {
      const castedData = data as Response["data"];
      return { data: castedData.role };
  }

  return { error };
}
