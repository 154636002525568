import { FetchService } from "../FetchService";
import { companyURI } from "./uri";
import mockData from "./mockCompanyById.json";

interface Office {
  id: string;
  name: string;
  logo: string;
  boolean: boolean;
  slug?:string
}

interface Response {
  error?: boolean;
  data?: Office;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getCompanyById(id: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + companyURI + "/form/" + id;

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
