import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  EmbeddedTravellerFormMessageId,
} from "@triporate/triporate-design-system";
import { SubmitResultHandler } from "../../../hooks/useTravellerForm/types";
import TravellersBaseForm from "../../Travellers/TravellersBaseForm";

const EmbeddedTravellersForm: FunctionComponent = () => {
  const postWindowMessage = (msg: string) => {
    const parentLocation = window.location.ancestorOrigins[0];
    window.parent.postMessage(msg, parentLocation);
  };

  const onSuccess: SubmitResultHandler = (data) => {
    const messageId = EmbeddedTravellerFormMessageId.onSuccess;
    const content = JSON.stringify(data);
    postWindowMessage(`${messageId} - ${content}`);
  };

  const onError: SubmitResultHandler = (data) => {
    const messageId = EmbeddedTravellerFormMessageId.onError;
    const content = JSON.stringify(data);
    postWindowMessage(`${messageId} - ${content}`);
  };

  return (
    <Row justify="center" style={{ height: "100vh" }}>
      <Col>
        <TravellersBaseForm
          embeddedMode
          onSuccess={onSuccess}
          onError={onError}
        />
      </Col>
    </Row>
  );
};

export default EmbeddedTravellersForm;
