import { FetchService } from "../FetchService";

interface Response {
  errorMessage: string;
  enabled: boolean;
}

const handleMockData = () => ({
  errorMessage: "Invalid",
  enabled: false,
});

export async function getFormItemIsVisible(
  uri: string,
  id: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  try {
    const url = fetchService.baseUrl + uri + id;
    const { data } = await fetchService.get(url);

    return data as Response;
  } catch (e) {
    return handleMockData();
  }
}
