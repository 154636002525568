import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  List,
  Icons,
  validateIconType,
  Switch,
  Card,
  Input,
  PageHeader,
  TripoCard,
  TripoInjectHTML,
  Button
} from "@triporate/triporate-design-system";
import { getTravellers, IParams, Meta } from "../../../services/Travellers";
import { ValidPath } from "../../../RouterSwitch";
import { TravellersConfigData, TravellersListData } from "./type";
import EnableTravellerModal from "./EnableTravellerModal";
import "./List.scss";
import CreateHeaderButton from "../../Companies/CreateHeaderButton";

const TravellersList = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = React.useState<IParams>();
  const [paginationData, setPaginationData] = React.useState<Meta & { total: number }>();
  const [listData, setListData] = useState<TravellersListData>([]);
  const [travellersConfigData, setTravellersConfigData] =
    useState<TravellersConfigData>();
  const [travellerModalData, setTravellerModalData] = useState<{
    id: string;
    enable: boolean;
    name: string;
  }>();

  React.useEffect(() => {
    handleFetch(params)
  }, [])

  const handleFetch = useCallback(async (params) => {
    setLoading(true);
    const { data } = await getTravellers(params);

    if (data) {

      setListData(
        data.list
      );
      setTravellersConfigData(data.config);
      setPaginationData({
        ...data.meta,
        total: data.meta.totalElements
      })
    }
    setLoading(false);
  }, [])

  React.useEffect(() => {

    if (!params) return

    const handler = setTimeout(() => {

      handleFetch(params)

    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(handler);
    }

  }, [params?.search, handleFetch])

  React.useEffect(() => {

    if (!params) return


    handleFetch(params)


  }, [params?.page, params?.pageSize, handleFetch])

  const handleChangePageSizeEvent = (page: any, pageSize: number) => setParams((prevState: any) => ({ ...prevState, page: 1, pageSize }))

  const handleChangePageEvent = (page: any) => setParams((prevState: any) => ({ ...prevState, page }))

  const handleSearchEvent = (e: any) => {

    const value = e.target.value

    if (!value || value.length == 0) {
      setParams((prevState: any) => ({ ...prevState, page: 1, search: undefined }))
      return
    }

    if (value.length < 3) return

    setParams((prevState: any) => ({ ...prevState, page: 1, search: value }))
  }

  const customRenderItem = ({
    name,
    id,
    company,
    enable,
  }: Record<string, string | boolean | undefined>) => (
    <List.Item
      className="travellers-list-item list-item"
      actions={[
        <Link
          to={`/${ValidPath.travellers}/update/${id}`}
          key="list-loadmore-edit"
        >
          <span>
            <Icons icon={validateIconType("edit")} />
            {travellersConfigData?.list.button}
          </span>
        </Link>,
        <Switch
          key="list-activate"
          checked={!!enable}
          onChange={() => {
            setTravellerModalData({
              id: `${id}`,
              name: `${name}`,
              enable: !!enable,
            });
          }}
        />,
      ]}
    >
      <Link
        to={`/${ValidPath.travellers}/update/${id}`}
        key="list-loadmore-edit"
        className="no-text-underline travellers-list-link"
      >
        <List.Item.Meta title={name} description={company} />
      </Link>
    </List.Item>
  );

  return (
    <>
      <Fragment>
        <PageHeader
          title={travellersConfigData?.pageHeader?.title}
          extra={[
            <Button type="primary" key="create-header-button">
              <Link className="no-text-underline" to={`/travellers/create`}>
                {travellersConfigData?.pageHeader?.button || 'New traveller'}
              </Link>
            </Button>
          ]}
        />
        {travellersConfigData?.pageHeader?.text && (
          <TripoInjectHTML
            text={travellersConfigData?.pageHeader?.text}
            style={{ paddingLeft: '12px', paddingBottom: '20px' }}
          />
        )}
        <Card
          type="inner"
          title="Lista de viajeros"
          extra={
            <Input
              allowClear
              onChange={handleSearchEvent}
              placeholder="Buscar"
            />}>

          <List
            loading={loading}
            pagination={{
              ...paginationData,
              current: paginationData?.page,
              onChange: handleChangePageEvent,
              onShowSizeChange: handleChangePageSizeEvent
            }}
            dataSource={listData as any}
            renderItem={customRenderItem}
          />

        </Card>

        <EnableTravellerModal
          travellersData={travellerModalData}
          config={travellersConfigData?.enableModal}
          cleanTravellersData={() => setTravellerModalData(undefined)}
          updateTravellerListData={(id, enable) => {
            setListData((prevState) =>
              prevState.map((traveller) => {
                if (traveller.id === id) {
                  return { ...traveller, enable };
                }
                return traveller;
              })
            );
          }}
        />
      </Fragment>
    </>
  );
};

export default TravellersList;
