import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import OfficesForm from "../OfficesForm";
import List from "../List";
import { ValidPath } from "../../../RouterSwitch";
import Users from "../Users";

const OfficesSwitch: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={`/${ValidPath.offices}/create`} component={OfficesForm} />
      <Route
        path={`/${ValidPath.offices}/update/:officeId`}
        component={OfficesForm}
      />
      <Route path={`/${ValidPath.offices}/users/:officeId`} component={Users} />
      <Route path={`/${ValidPath.offices}`} component={List} />
      <Redirect from="*" to={`/${ValidPath.offices}`} />
    </Switch>
  );
};

export default OfficesSwitch;
