import React, { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useCustomTabFavicon,
  useUniverseTheme,
} from "@triporate/triporate-design-system";

import { ConfigState } from "../../../store/config";

const UniverseStyleContainer: FunctionComponent = ({
  children,
}): JSX.Element => {
  const mapState = (state: ConfigState): ConfigState["config"] => state.config;
  const { universe, favicon } = useSelector(mapState);

  useUniverseTheme(universe);
  useCustomTabFavicon(favicon);

  return <>{children}</>;
};

export default UniverseStyleContainer;
