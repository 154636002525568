import { useParams } from "react-router-dom";
import { parseParamsById } from "../utils/parseParamsById";

function useParamById(property: string): [string | null] {
  const params: Record<string, unknown> = useParams();

  return [parseParamsById(params, property)];
}

export default useParamById;
