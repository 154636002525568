import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  List,
  Icons,
  validateIconType,
  PaginatedList,
} from "@triporate/triporate-design-system";
import { getUsers } from "../../../services/Users";
import { ValidPath } from "../../../RouterSwitch";
import { UsersConfigData, UsersListData } from "./type";
import "./UsersList.scss";

export enum ValidDisplayRoles {
  agent = "Agent",
  admin = "Admin",
  travel_manager = "Travel Manager",
  traveller = "Traveller",
  superadmin = "Super Admin",
}

const UsersList = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState<UsersListData>([]);
  const [usersConfigData, setUsersConfigData] = useState<UsersConfigData>();

  useEffect(() => {
    let isMounted = true;

    const handleFetch = async () => {
      setLoading(true);
      const { data } = await getUsers();

      if (!isMounted) {
        return;
      }

      if (data) {
        setListData(data.list.sort((a, b) => (a.name > b.name ? 1 : -1)));
        setUsersConfigData(data.config);
      }
      setLoading(false);
    };
    handleFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  const validateDisplayRole = (
    value?: string | boolean | undefined
  ): ValidDisplayRoles | undefined => {
    const strRole = ValidDisplayRoles[value as keyof typeof ValidDisplayRoles];
    return strRole;
  };

  const customRenderItem = ({
    name,
    id,
    role,
    officeName,
    officeId,
  }: Record<string, string | boolean | undefined>) => (
    <List.Item
      className="list-item"
      actions={[
        <Link to={`/${ValidPath.users}/update/${id}`} key="list-loadmore-edit">
          <span>
            <Icons icon={validateIconType("edit")} />
          </span>
          <span>{usersConfigData?.list.button}</span>
        </Link>,
      ]}
    >
      <List.Item.Meta
        title={
          <Link
            to={`/${ValidPath.users}/update/${id}`}
            key="list-loadmore-edit"
            className="users-list-link no-text-underline"
          >
            {name}
          </Link>
        }
        description={
          <div>
            <Link
              to={`/${ValidPath.users}/update/${id}`}
              key="list-loadmore-edit"
              className="users-list-link no-text-underline"
            >
              <span>{validateDisplayRole(role)}</span>
            </Link>
            {!officeName || !officeId ? (
              <></>
            ) : (
              <>
                <span className="links-divider">-</span>
                <Link
                  to={`/${ValidPath.offices}/update/${officeId}`}
                  key="list-loadmore-edit"
                  className="users-list-link no-text-underline"
                >
                  {officeName}
                </Link>
              </>
            )}
          </div>
        }
      />
    </List.Item>
  );

  return (
    <>
      <PaginatedList
        listData={listData}
        configData={usersConfigData}
        loading={loading}
        customRenderItem={customRenderItem}
        handleNavigationClick={(to) => {
          history.push(`/${ValidPath.users}${to}`);
        }}
      />
    </>
  );
};

export default UsersList;
