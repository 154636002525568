import React, { FunctionComponent } from "react";
import moment from "moment";
import { SpinnerInContainer } from "@triporate/triporate-design-system";
import { FormConfig } from "../../../services/BillingFulfillment/interface";
import { useParamById } from "../../../hooks";
import { FormValues, DateFormat, InputType } from "../FormGenerator/interface";
import FormGenerator from "../FormGenerator";
import { FormSubmitProps } from "./types";
import { useFormSubmit } from "./useFormSubmit";
import { useFormConfig } from "./useFormConfig";

import "./BillingFulfillmentBaseForm.scss";

interface BillingFulfillmentBaseFormProps extends FormSubmitProps {
  paramName?: string;
}

const BillingFulfillmentBaseForm: FunctionComponent<
  BillingFulfillmentBaseFormProps
> = ({ onSuccess, onError, paramName = "offerId" }) => {
  const [paramOfferId] = useParamById(paramName);

  const { loadingConfig, offerId, formConfig, initialValues } = useFormConfig({
    offerId: paramOfferId || undefined,
  });

  const { formSubmitHandler, loadingSubmit } = useFormSubmit({
    onSuccess,
    onError,
  });

  if (loadingConfig || !formConfig) {
    return <SpinnerInContainer />;
  }

  const formatValues = (
    inputsConfig: FormConfig["inputs"],
    rawValues: FormValues
  ): FormValues => {
    const rawCopy = { ...rawValues };
    inputsConfig.forEach(({ type, name }) => {
      if (type !== InputType.date) return;
      const rawValue = rawCopy[name];
      if (!moment.isMoment(rawValue)) {
        return;
      }
      rawCopy[name] = rawValue.format(DateFormat);
    });
    return rawCopy;
  };

  return (
    <div className="embedded-form-container">
      <FormGenerator
        loading={loadingSubmit}
        formData={formConfig}
        initialValues={initialValues}
        onFinish={(values) => {
          if (offerId) {
            const formatedValues = formatValues(formConfig.inputs, values);
            formSubmitHandler(formatedValues, offerId);
          }
        }}
      />
    </div>
  );
};

export default BillingFulfillmentBaseForm;
