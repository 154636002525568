import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  EmbeddedTravellerFormMessageId, // TP-6815: add EmbeddedBillingFulfillmentFormMessageId
} from "@triporate/triporate-design-system";
import { SubmitResultHandler } from "../../../hooks/useTravellerForm/types";
import BillingFulfillmentBaseForm from "../../BillingFulfillment/BillingFulfillmentBaseForm";

const EmbeddedBillingFulfillmentForm: FunctionComponent = () => {
  const postWindowMessage = (msg: string) => {
    const parentLocation = window.location.ancestorOrigins[0];
    window.parent.postMessage(msg, parentLocation);
  };

  const onSuccess: SubmitResultHandler = (data) => {
    const messageId = EmbeddedTravellerFormMessageId.onSuccess;
    const content = JSON.stringify(data);
    postWindowMessage(`${messageId} - ${content}`);
  };

  const onError: SubmitResultHandler = (data) => {
    const messageId = EmbeddedTravellerFormMessageId.onError;
    const content = JSON.stringify(data);
    postWindowMessage(`${messageId} - ${content}`);
  };

  return (
    <Row justify="center" style={{ height: "100vh" }}>
      <Col span={12}>
        <BillingFulfillmentBaseForm onSuccess={onSuccess} onError={onError} />
      </Col>
    </Row>
  );
};

export default EmbeddedBillingFulfillmentForm;
