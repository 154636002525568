import React, { Fragment, FunctionComponent } from "react";
import { InputDataProps, InputType } from "../interface";
import DateInput from "./DateInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

const InputSwitch: FunctionComponent<InputDataProps> = ({ data, ...props }) => {
  switch (data.type) {
    case InputType.text:
    case InputType.num:
    case InputType["text/num"]:
      return <TextInput {...props} data={data} />;
    case InputType.select:
      return <SelectInput {...props} data={data} />;
    case InputType.date:
      return <DateInput {...props} data={data} />;
    default:
      return <Fragment></Fragment>;
  }
};

export default InputSwitch;
