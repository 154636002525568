import { FetchService } from "../FetchService";

interface FailedUpsert {
  message: string;
  description: string;
}

interface Response {
  error?: boolean;
  data: FailedUpsert;
}

export async function sendWelcomeEmail(body: string): Promise<Response> {
  const fetchService = new FetchService();
  const url = fetchService.baseUrl + "/emails/sendWelcomeEmail";

  const { error, data } = await fetchService.post(url, body);
  const castedData = data as FailedUpsert;
  return { error, data: castedData };
}
