export const parseParamsById = (
  params: Record<string, unknown>,
  property: string
): string | null => {
  let paramValue: unknown;
  if (property in params) {
    paramValue = params[property];
  }

  if (typeof paramValue === "string") return paramValue;

  if (Array.isArray(paramValue)) return paramValue[0];

  return null;
};
