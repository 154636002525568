import { FetchService } from "../FetchService";
import { travellerURI } from "./uri";

interface FailedUpsert {
  message: string;
  description: string;
}

interface Response {
  error?: boolean;
  data: FailedUpsert;
}

export async function upsertTravellerEnable(
  body: string,
  id: string | null
): Promise<Response> {
  const fetchService = new FetchService();
  let url = fetchService.baseUrl + travellerURI + "/enable";
  if (id) {
    url += `/${id}`;
    const { error, data } = await fetchService.put(url, body);
    const castedData = data as FailedUpsert;
    return { error, data: castedData };
  }

  const { error, data } = await fetchService.post(url, body);
  const castedData = data as FailedUpsert;
  return { error, data: castedData };
}
