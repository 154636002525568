import React, { FunctionComponent, useCallback } from "react";
import {
  SpinnerInContainer,
  PageHeader,
  FormGenerator,
} from "@triporate/triporate-design-system";
import { DefaultEditor, ContentEditableEvent } from "react-simple-wysiwyg";
import { getSelectOptions } from "../../../services/SelectOptions";
import { uploadAsset } from "../../../services/Assets";
import { useTravellerFormConfig } from "../../../hooks";
import { SubmitResultHandler } from "../../../hooks/useTravellerForm/types";
import GoBackHeaderButton from "../GoBackHeaderButton";
import "./TravellersBaseForm.scss";
import ModalWelcome from "../../ModalWelcome";

interface TravellersBaseFormProps {
  embeddedMode?: boolean;
  onSuccess?: SubmitResultHandler;
  onError?: SubmitResultHandler;
  agentsMode?: boolean;
  paramName?: string;
}

const TravellersBaseForm: FunctionComponent<TravellersBaseFormProps> = ({
  embeddedMode,
  onSuccess,
  onError,
  agentsMode = false,
  paramName = "travellerId",
}) => {
  const {
    loadingConfig,
    loadingInitialValues,
    formInputsConfig,
    initialValues,
    sectionTitles,
    btnText,
    saveBtnText,
    confirmationModalData,
    travellerId,
    mode,
    inputValidationWithTravellerId,
    travellerFormSubmitHandler,
    loadingSubmit,
    handleIsVisible,
    modalData,
  } = useTravellerFormConfig({
    agentsMode,
    paramName,
    onSuccess,
    onError,
  });

  const getContainerClassName = useCallback((): string | undefined => {
    if (!embeddedMode) return;
    return "embedded-form-container";
  }, [embeddedMode]);

  if (loadingConfig || loadingInitialValues) {
    return <SpinnerInContainer />;
  }

  return (
    <div className={getContainerClassName()}>
      {!embeddedMode && (
        <PageHeader
          title={sectionTitles[mode]}
          extra={[
            <GoBackHeaderButton key="go-back-header-button" text={btnText} />,
          ]}
        />
      )}
      <FormGenerator
        submitButtonLabel={saveBtnText}
        formData={formInputsConfig}
        getSelectOptions={getSelectOptions}
        initialValues={initialValues}
        onFinish={(values) => travellerFormSubmitHandler(values, travellerId)}
        loading={loadingSubmit}
        uploadAsset={uploadAsset}
        inputValidator={inputValidationWithTravellerId}
        isUpdate={!!travellerId}
        confirmationModalData={confirmationModalData}
        mode={mode}
        handleIsVisible={handleIsVisible}
        wysiwygFactory={() => ({
          component: DefaultEditor,
          onChangeHandler: (payload: unknown) => {
            const event = payload as ContentEditableEvent;
            return event?.target?.value;
          },
        })}
      />
      {modalData && (
        <ModalWelcome
          modalData={modalData}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    </div>
  );
};

export default TravellersBaseForm;
